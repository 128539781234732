import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { AzureConfig } from '../config/AzureConfig';

export let reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: AzureConfig.connectionString,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

export const getAppInsights = (): ApplicationInsights => appInsights;
