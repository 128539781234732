declare global {
  interface Window {
    chrome?: any;
    opr?: any;
  }
}

const fnBrowserDetect = (): string => {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'MS Edge';
      case agent.indexOf('edg/') > -1:
        return 'MS Edge';
      case agent.indexOf('opr') > -1 && !!window.opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!window.chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'MS IE';
      case agent.indexOf('firefox') > -1:
        return 'Mozilla Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'No browser detected';
    }
  };
  
  const isEmptyObject = (obj: Record<string, any>): boolean => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false; // If there's at least one property, the object is not empty
      }
    }
    return true; // If no properties are found, the object is empty
  };
  
  export { fnBrowserDetect, isEmptyObject };
  