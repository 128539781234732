import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";

interface VersusLineChartsProps {
  data: any;
  label1Color: string;
  line1Color: string;
  label2Color: string;
  line2Color: string;
  legend: boolean;
  label1Width: number;
  label2Width: number;
  label1AdjustVal: number;
  label2AdjustVal: number;
  rightVal?: number
  leftVal?: number
  topVal?: number
  bottomVal?: number
  firstDataKey: string
  secondDataKey: string
  xAxisParam?: any
  xAxisInterval?: number
  chartName?: any
}


const renderCustomizedLabel = (props: any) => {
  const { x, y, stroke, value, labelColor, labelWidth, labelAdjustVal } = props;
  const marginBottom = 10; // Adjust this value to set the margin

  const handleCovertThousandsToK = (val:any)=>{
    return val<1000?val:(val/1000).toFixed(1)+"K"
  }

  return (

    <g>
      <rect
        x={x + labelWidth / 2 - 17}
        y={y - 22}
        width={45}
        height={20}
        fill={labelColor}
        rx={5}
        ry={5}
      />
      <text x={x + labelAdjustVal + labelWidth / 2} y={y - 11} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {/* {value.split(' ')[1]} */}
        {handleCovertThousandsToK(value)}
      </text>
    </g>
  );
};



export default function VersusLineChart({ chartName, data, xAxisInterval, xAxisParam, label1Color, line1Color, label2Color, line2Color, legend, label1Width, label2Width, label1AdjustVal, label2AdjustVal, rightVal, topVal, leftVal, bottomVal, firstDataKey, secondDataKey }: VersusLineChartsProps) {

  const smartphoneVsBBTrendTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
          {payload?.value?.split("-")[1]}
        </text>
      </g>
    );
  };

  const getTicksXAxis = () => {
    if (chartName === "smartphoneVsBBTrend")
      return smartphoneVsBBTrendTick

    return undefined
  }


  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: topVal ? topVal : 5,
          right: rightVal ? rightVal : 30,
          left: leftVal ? leftVal : 20,
          bottom: bottomVal ? bottomVal : 5
        }}

      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisParam ? xAxisParam : "name"} tick={getTicksXAxis()} interval={xAxisInterval ? xAxisInterval : 0} />
        <YAxis />
        <Tooltip />
        {legend && <Legend />}
        <Line type="monotone" dataKey={firstDataKey} stroke={line1Color} >
          <LabelList content={(props) => renderCustomizedLabel({ ...props, labelColor: label1Color, labelWidth: label1Width, labelAdjustVal: label1AdjustVal })} />
        </Line>
        <Line type="monotone" dataKey={secondDataKey} stroke={line2Color}>
          <LabelList content={(props) => renderCustomizedLabel({ ...props, labelColor: label2Color, labelWidth: label2Width, labelAdjustVal: label2AdjustVal })} />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
}