export interface EnvironmentData {
  run: keyof typeof environmentData;
  development: EnvironmentConfig;
  staging: EnvironmentConfig;
  production: EnvironmentConfig;
}

export interface EnvironmentConfig {
  clientId: string;
  authority: string;
  tenantId: string;
  "api-scope": string;
  appInsightConnectionString: string;
  gpt3key:string;
  gpt4key:string;
  chatApiUrl?: string;
  hapiSubscriptionKey?:string
}
export const environmentData:any = {
  run: "development",

  development: {
    clientId: "b24cb888-21d8-468a-b12d-c6215c405eb9",
    // clientId: "5d685861-9342-4746-a1d4-caa839331ce4",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid b24cb888-21d8-468a-b12d-c6215c405eb9/user_impersonation",
    // "api-scope" :"b24cb888-21d8-468a-b12d-c6215c405eb9/.default",
    appInsightConnectionString:
      "InstrumentationKey=59459088-55d4-4c26-ad97-fa5503e896aa;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=e8cda839-8cc9-461e-84d0-1237eddcea1c",
    gpt3key: "gpt-35-turbo-test",
    gpt4key: "gpt-4-turbo-test",
    chatApiUrl: "https://aps-storeiservice-core-api-dev.azurewebsites.net"
    // chatApiUrl: "https://gateway-test.hapi.hmgroup.com/storeiservice/v1"
  },
  staging: {
    clientId: "b24cb888-21d8-468a-b12d-c6215c405eb9",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid b24cb888-21d8-468a-b12d-c6215c405eb9/user_impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-test",
    gpt4key: "gpt-4-turbo-test",
    chatApiUrl: "https://aps-storeiservice-core-api-dev.azurewebsites.net"
  },
  production: {
    clientId: "060c6088-161d-4639-8603-9ee185e64e1b",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid 060c6088-161d-4639-8603-9ee185e64e1b/.default",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt35_8K",
    gpt4key: "gpt-4-turbo",
    chatApiUrl: "https://aps-storeiservice-core-api.azurewebsites.net"
  },
} as EnvironmentData;
