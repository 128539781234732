import { getAppInsights } from '../../../services/TelemetryService';
import { GetUserName } from '../../../services/AuthUser';
import { fnBrowserDetect } from '../../utils/utils';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface UserDetails {
  userName: string;
  userEmail: string;
}

const logAPIResponse = (data: Record<string, any>, description: string): void => {
  const userDetails: UserDetails[] | undefined = GetUserName();
  const appInsights = getAppInsights();

  appInsights?.trackTrace({
    message:
      'User Name: ' +
      userDetails?.[0]?.userName +
      ' User Email: ' +
      userDetails?.[0]?.userEmail +
      ' Browser: ' +
      fnBrowserDetect() +
      description,
    severityLevel: SeverityLevel.Information,
    properties: data,
  });
};

export { logAPIResponse };
