import React, { useEffect, useState } from "react";
import styles from "./InstoreCustCareProd.module.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CImage,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import AppHeaderMobile from "../../components/AppHeaderMobile";
import { GetUserName } from "../../services/AuthUser";
import {
  Button,
  Modal,
  Checkbox,
  Select,
  Breadcrumb,
  Divider,
  Row,
  Col,
  DatePicker,
  Space,
  DatePickerProps,
  Input,
  Collapse,
  Popover,
  Flex,
} from "antd";
import type { CheckboxProps, CollapseProps, GetProp, SelectProps } from "antd";
import AppHeader from "../../components/AppHeader";
import stores from "../../data/stores.json";
import countries from "../../data/countries.json";
import TextArea from "antd/es/input/TextArea";
import { CardSection } from "../../components/CommonComponents/CardSection";
import { CardSectionNew } from "../../components/CommonComponents/CardSectionNew";
import ApplicationDetails from "../../components/ApplicationDetails/ApplicationDetails";
import InstoreCustCare from "../../components/InstoreCustCare/InstoreCustCare";
import ErrorBoundaryComponent from "../../components/ErrorBoundaryComponent/ErrorBoundaryComponent";

type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[number];
const { Option: AntOption } = Select; // Renaming 'Option' to 'AntOption'

interface OptionType {
  value: string;
  label: string;
}
const CheckboxGroup = Checkbox.Group;

const plainOptions = [
  { label: <span className="check-handm-icon"></span>, value: "H&m" },
  { label: <span className="check-cos-icon"></span>, value: "COS" },
  { label: <span className="check-weekday-icon"></span>, value: "WEEKDAY" },
  { label: <span className="check-monkl-icon"></span>, value: "MONKL" },
  {
    label: <span className="check-otherstories-icon"></span>,
    value: "Other Stories",
  },
  { label: <span className="check-arket-icon"></span>, value: "ARKET" },
];
const defaultCheckedList = ["Apple", "Orange"];
const { Option } = Select;

const InstoreCustCareProd = () => {
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList);
  const navigate = useNavigate();
  const onCollapseChange = (key: string | string[]) => {
    console.log(key);
  };
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleAddApplicationChange = (selected: string[]) => {
    setSelectedOptions(selected);
  };

  const handleCheckboxChange = (value: string) => {
    if (value === "Select All") {
      setSelectedOptions([
        "Select All",
        ...options.map((option) => option.value),
      ]);
    } else if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const options: OptionType[] = [
    { value: "InStore Customer Care", label: "InStore Customer Care" },
    { value: "Smartphones in Store", label: "Smartphones in Store" },
    { value: "SIM", label: "SIM" },
    { value: "Delcon Web", label: "Delcon Web" },
  ];

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };
  const onCheckBoxChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    setCheckedList(
      e.target.checked ? plainOptions.map((option) => option.value) : []
    );
  };
  const cardsDetails = [
    { title: "STORE SMARTPHONE", id: 1, count: 0 },
    { title: "CUSTOMER COUNTER", id: 2, count: 0 },
    { title: "INSTORE CUST CARE - PROD", id: 3, count: 0 },
    { title: "DIGITAL SCREENS & MEDIA - PROD ", id: 4, count: 0 },
    { title: "DELCON - PROD", id: 5, count: 3 },
    { title: "APPLICATION NAME", id: 6, count: 3 },
    { title: "APPLICATION NAME", id: 7, count: 3 },
    { title: "APPLICATION NAME", id: 8, count: 3 },
    { title: "STORE 0365", id: 9, count: 3 },
    { title: "RCS ECC SIM - P10 - PROD", id: 10, count: 3 },
    { title: "Store Smartphone RFID - PROD", id: 11, count: 3 },
    { title: "ORIS 3 App", id: 12, count: 3 },
  ];
  const onCheckChange = (e: any) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const content = (
    <div>
      <Select
        mode="multiple"
        style={{ width: 200 }}
        value={selectedOptions}
        onChange={handleAddApplicationChange}
      >
        <AntOption key="Select All" value="Select All">
          <Checkbox
            onChange={() => handleCheckboxChange("Select All")}
            checked={selectedOptions.includes("Select All")}
          />
          Select All
        </AntOption>
        {options.map((option) => (
          <AntOption key={option.value} value={option.value}>
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={selectedOptions.includes(option.value)}
            />
            {option.label}
          </AntOption>
        ))}
      </Select>
      <Flex gap="small">
        <Button className={styles.CancelBtn}>Cancel</Button>
        <Button className={styles.AddBtn}>Add</Button>
      </Flex>
    </div>
  );
  
  
  return (
    <div>
      <ErrorBoundaryComponent>
      <InstoreCustCare />
      </ErrorBoundaryComponent>
    </div>
  );
};
export default InstoreCustCareProd;
