import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import styles from "./ErrorBoundaryComponent.module.css"


const handleReloadPage = () => {
    window.location.reload()
}

function fallbackRender({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) {
  return (
    <div className={styles.fallbackCompContainer}>
    <div style={{textAlign:"center"}}>
      <p className={styles.errorText}>Something went wrong</p>
      {/* <pre style={{ color: "red" }}>{error.message}</pre> */}
      <button className={styles.tryAgainBtn} onClick={handleReloadPage}>Try again</button>
    </div>
    </div>
  );
}

function ErrorBoundaryComponent({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary
      FallbackComponent={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
      onError={(error, errorInfo) => {
        // Log the error to an error reporting service
        console.error("Error caught by Error Boundary:", error, errorInfo);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryComponent;