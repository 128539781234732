import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./CustomerCounter.module.css";
import { notification, Spin, Divider, Table, Tooltip, Modal, Popover } from "antd";
import CustomTable from "../../components/CommonComponents/Table";
import Header from "../../components/CommonComponents/Header";
import DatePickerComponent from "../../components/CommonComponents/DatePicker";
import DropDownList from "../../components/CommonComponents/DropDownList";
import "./CustomerCounter.module.css";
import { CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import dayjs from "dayjs";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import { GetLoggedInCountry } from "../../services/AuthUser";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import { GetUserName } from "../../services/AuthUser";
import { DeleteOutlined } from "@ant-design/icons";
import ExportToXslx from "../../components/CommonComponents/ExportToXlsx";
import { logAPIResponse } from "../../components/CommonUtils/utils/logs_Utils";

const formatDateToDDMMYYYY = (value: any) => {
  const date = new Date(value);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const CustomerCounter = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  let today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [storesList, setStoresList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedStores, setSelectedStores] = useState<any>([]);
  const [customerCounterData, setCustomerCounterData] = useState([]);
  const [startDate, setStartDate] = useState<any>();
  const [selectedStoreId, setSelectedStoreId] = useState<string[]>([]);
  const [loading, setLoading] = useState(false); // New loading state
  const [isModalOpen, setIsModalOpen] = useState(false); // New loading state
  const [statusDataStoreId, setStatusDataStoreId] = useState(""); // New loading state
  const [storeVisitorReport, setStoreVisitorReport] = useState<any>([]); // New loading state
  const [endTransaction, setEndTransaction] = useState<any>(""); // New loading state
  const [startTransaction, setStartTransaction] = useState<any>(""); // New loading state
  const [loadingVisitorReport, setLoadingVisitorReport] = useState(false); // New loading state
  const [storeFilterResponse, setStoreFilterResponse] = useState<any>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<any>("");
  const [countryOptions, setCountryOptions] = useState<any | null>([]);
  const [selectedAllCountry, setSelectedAllCountry] = useState(false);
  const [selectedCountryWithCode, setSelectedCountryWithCode] = useState<any>(
    []
  );
  const [uncheckingList, setUncheckingList] = useState<any>([]);
  const [selectedStoreToMsgName, setSelectedStoreToMsgName] = useState("");
  const [selectedStoreToMsg, setSelectedStoreToMsg] = useState<any>([]);
  const [uncheckStoresToMsgList, setUncheckStoresToMsgList] = useState<any>([]);
  const [storeToMsgList, setStoreToMsgList] = useState<any>([]);
  const [showSendMsgToStoreDialog, setShowSendMsgToStoreDialog] =
    useState(false); // show send msg to store dialog
  const [messageTostore, setMessageTostore] = useState(""); // show send msg to store dialog
  const [defaultStores, setDefaultStores] = useState<any>([]);
  const [defaultSelectedCountryId, setDefaultSelectedCountryId] = useState<any>(
    []
  );
  const [showEditMsgDialog, setShowEditMsgDialog] = useState<any>({}) // show send msg to store dialog
  const [editStoreMsg, setEditStoreMsg] = useState({ storeId: "", storeMsg: "" }) // show send msg to store dialog
  const [editStoreMsgBuffer, setEditStoreMsgBuffer] = useState({ storeId: "", storeMsg: "" }) // show send msg to store dialog
  const [isActiveCounterModalOpen, setIsActiveCounterModalOpen] = useState(false); // New loading state
  const [entranceDetails, setEntanceDetails] = useState<any>([]);
  const [loadingEntranceDetails, setLoadingEntranceDetails] = useState(false); // New loading state
  const [selectedEntranceCountry, setSelectedEntranceCountry] = useState(""); // New loading state


  const { confirm } = Modal;


  // User details
  let userDetails = GetUserName();
  const userEmail = userDetails?.[0]?.userEmail;

  // Context provide
  const { clearCustCounterStore, setClearCustCounterStore } = useAppContext();

  const headerList = [
    { header: "Store id", key: "id" },
    { header: "Popular name", key: "popularName" },
    { header: "Number of active counters", key: "numberOfActiveCounters" },
    {
      header: "Service notification message",
      key: "serviceNotificationMessage",
    },
    { header: "2 Days average accuracy", key: "averageOfSensorAccLast2BusinessDays" },
    { header: "Sensor accuracy", key: "sensorAccuracy" },
    { header: "Status", key: "status" },
  ];

  const sensorAccuracyColorCode = (accuracy: any) => {
    let color = "";
    if (accuracy >= 95) {
      color = "#4CAF50"; // Green
    } else if (accuracy >= 90 && accuracy < 95) {
      color = "#FB8C00"; // Amber
    } else if (accuracy < 90) {
      color = "#C80F56"; // Red
    }
    return color;
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (startDate) {
      params.set("reportDate", startDate);
      params.set(
        "countries",
        selectedCountryId?.toString() || defaultSelectedCountryId?.toString()
      );
      params.set(
        "storeId",
        selectedStoreId?.toString() || defaultStores?.toString()
      );
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [
    startDate,
    selectedCountryId,
    defaultSelectedCountryId,
    selectedStoreId,
    storeFilterResponse,
  ]);

  const handleMessageChange = (event: any, rowData: any) => {
    const updatedData: any =
      customerCounterData?.map((item: any) => {
        if (item?.id === rowData.id) {
          return { ...item, serviceNotificationMessage: event.target.value };
        }
        return item;
      }) || [];
    setCustomerCounterData(updatedData);
  };

  const handleCloseEditMsgDialog = () => {
    setShowEditMsgDialog(false)
  }

  const handleEditMsgToStore = async () => {
    const params: any = {
      storeIds: [editStoreMsg.storeId],
      userId: userEmail,
      serviceMessage: editStoreMsg.storeMsg,
    };
    if (editStoreMsg.storeMsg?.trim()?.length >= 5) {
      await sendServiceNotificationMessage(params);
      handleCloseEditMsgDialog();
      const allFilterStoresList = storesList?.map((item: any) => item?.value);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const reportParam = {
        reportDate: startDate
          ? formatDateToDDMMYYYY(startDate)
          : formatDateToDDMMYYYY(yesterday),
        storeId:
          selectedStoreId?.length > 0 ? selectedStoreId : allFilterStoresList,
      };
      await fetchCustomerCounterReport(reportParam);
    } else {
      let validationErrMsg = "";
      if (messageTostore?.trim()?.length < 5) {
        validationErrMsg += "The message should be minimum 5 characters.";
      }
      alert(validationErrMsg);
    }
  };

  const editStoreMsgContent = (
    <div className={styles.editMsgToStoreContainer}>
      <div className={styles.sendMsgHeading}>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontWeight: "bold", paddingTop: "4px" }}><span>Edit Message</span> <Divider
          type="vertical"
          style={{ backgroundColor: "black", marginTop: "2px", width: "2px", height: "19px" }}
        ></Divider> <span>{editStoreMsg?.storeId}</span></div>
        <span
          className={styles.closeIcon}
          onClick={() => {
            handleCloseEditMsgDialog()
          }}
        ></span>
      </div>
      <div style={{ position: "relative" }}>
        <textarea
          rows={5}
          value={editStoreMsg?.storeMsg || ""}
          className={styles.messageTxtArea}
          onChange={(e) => setEditStoreMsg((prev: any) => ({ ...prev, storeMsg: e.target.value }))}
        ></textarea>
        <span className={styles.clearEditMsg} onClick={() => { setEditStoreMsg(prev => ({ ...prev, storeMsg: "" })) }}>Clear</span>
      </div>
      <button
        className={styles.sendMsgBtn}
        onClick={() => {
          handleEditMsgToStore();
        }}
      >
        Send
      </button>
    </div>
  )

  const columns = [
    {
      title: "STORE ID",
      dataIndex: "id",
      key: "id",
      width: 150,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "POPULAR NAME",
      dataIndex: "popularName",
      key: "popularName",
      width: 250,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "NO. OF ACTIVE COUNTER",
      dataIndex: "numberOfActiveCounters",
      key: "numberOfActiveCounters",
      width: 200,

      render: (text: any, record: any) => <a onClick={() => {
        fetchEntranceDetails(record.id)
        setIsActiveCounterModalOpen(true)
        setSelectedEntranceCountry(record?.id)
      }} style={{ paddingRight: "10px" }}>{text}</a>,
    },
    {
      title: "2DAYS AVERAGE ACCURACY",
      dataIndex: "averageOfSensorAccLast2BusinessDays",
      key: "averageOfSensorAccLast2BusinessDays",
      width: 250,
      render: (text: any) => (
        <span
          style={{ color: text === null ? "" : sensorAccuracyColorCode(text) }}
        >{`${text === null ? "No Data available" : text + "%"}`}</span>
      ),
      sorter: (a: any, b: any) => a.averageOfSensorAccLast2BusinessDays - b.averageOfSensorAccLast2BusinessDays,
    },
    {
      title: "SENSOR ACCURACY",
      dataIndex: "sensorAccuracy",
      key: "sensorAccuracy",
      width: 200,
      render: (text: any) => (
        <span
          style={{ color: text === null ? "" : sensorAccuracyColorCode(text) }}
        >{`${text === null ? "No Data available" : text + "%"}`}</span>
      ),
      sorter: (a: any, b: any) => a.sensorAccuracy - b.sensorAccuracy,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 400,
      // sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      sorter: (a: any, b: any) => {
        const getNumber = (str: any) => {
          const match = str?.match(/\d+/);
          return match ? parseInt(match[0], 10) : 0;
        };
        return getNumber(a?.status) - getNumber(b?.status);
      },
      render: (text: any, record: any) => (
        <div>
          <a
            onClick={() => handleLaunchStoreVisitorReportModal(record)}
            style={{ color: record?.color ? record?.color : "" }}
          >
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "MESSAGE FOR STORE",
      dataIndex: "serviceNotificationMessage",
      key: "serviceNotificationMessage",
      width: 250,
      render: (text: any, record: any) => (
        <div className={styles.serviceMessageColumn}>
          {text?.length > 0 && (
            <div style={{ display: "flex", position: "relative", justifyContent: "center" }}>
              <Popover
                placement="leftTop"
                trigger="click"
                title={''}
                content={editStoreMsgContent}
                open={showEditMsgDialog[record.id] || false}
                onOpenChange={() => {
                  setEditStoreMsg({ storeId: record.id, storeMsg: text })
                  setShowEditMsgDialog((prev: any) => ({
                    ...prev,
                    [record.id]: !prev[record.id],
                  }))
                }}
              >
                <div style={{ position: "relative", }}>
                  <span className={styles.emailLogo}></span>
                </div>
              </Popover>
              <Divider
                type="vertical"
                style={{ backgroundColor: "black", position: "absolute", right: "43%", height: "19px" }}
              ></Divider>
              <button
                id="delete-btn"
                className={styles.iconbutton}
                onClick={() => { handleDelete(record?.id) }}
              >
                <DeleteOutlined />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleFetchReport = async () => {
    const allFilterStoresList = storesList?.map((item: any) => item?.value);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const reportParam = {
      reportDate: startDate
        ? formatDateToDDMMYYYY(startDate)
        : formatDateToDDMMYYYY(yesterday),
      storeId:
        selectedStoreId?.length > 0 ? selectedStoreId : allFilterStoresList,
    };
    await fetchCustomerCounterReport(reportParam);

  }

  const handleDelete = async (storeId: any) => {
    let token = await GetAccessToken();
    const param = { storeId: [storeId] }
    confirm({
      title: "Are you sure you want to delete this message?",
      className: "custom-confirm-modal",
      onOk() {
        fetch(
          `${environmentConfig.chatApiUrl}/CustomerCounter/DeleteServiceNotificationMessage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(param), // Empty body for the POST request
          }
        )
          .then((response) => {
            if (response.ok) {
              // successCount++;
              notification.success({
                message: "Success",
                description: "Notification(s) deleted successfully!",
              });
              handleFetchReport()
            } else {
              logAPIResponse([], `Failed to delete service notification message`)
              throw new Error("Failed to delete");
            }
          })
          .catch((error) => {
            logAPIResponse([], `Failed to delete service notification message ${error}`)

            // errorCount++;
            notification.error({
              message: "Error",
              description:
                "There was an error deleting one or more notifications.",
            });
          })
        // .finally(() => {
        //   checkCompletion();
        // });
        // }
      },
      onCancel() {
        // No action needed on cancel
      },
    });
  };

  const statusModalColumn = [
    {
      title: "Hours Slot",
      dataIndex: "timeInternal",
      key: "timeInternal",
      width: 250,
      render: (text: any) => {
        const val = text?.split("(");
        if (val[1])
          return (
            <span>
              {val[0]}
              <span style={{ color: "#0C59B2" }}>{"(" + val[1]}</span>
            </span>
          );
        else return <span>{text}</span>;
      },
    },
    {
      title: "In Count",
      dataIndex: "inCount",
      key: "inCount",
      width: 100,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Out Count",
      dataIndex: "outCount",
      key: "outCount",
      width: 100,
      render: (text: any) => <span>{text}</span>,
    },
  ];

  const entranceDetailsColumnsNonTurkey = [
    {
      title: "ENTRANCE ID",
      dataIndex: "entranceID",
      key: "entranceID",
      width: 100,
      render: (text: any) => <span style={{ marginLeft: "15px" }}>{text}</span>,
    },
    {
      title: "ENTRANCE NAME",
      dataIndex: "entranceName",
      key: "entranceName",
      width: 100,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "LIMA STATUS",
      dataIndex: "limaStatus",
      key: "limaStatus",
      width: 100,
      render: (text: any) => <span style={{ marginLeft: "15px" }}>{text === 1 ? "Active" : "Inactive"}</span>,
    },
    {
      title: "VISIT COUNT",
      dataIndex: "visitorCount",
      key: "visitorCount",
      width: 100,
      render: (text: any) => <span>{text}</span>,
    },
  ]

  const flattenedEntranceDetails = entranceDetails?.map((val: any) => {
    return { ...val, macId: val?.macAdresses?.map((macInfo: any) => macInfo?.macAddress), lanStatus: val?.macAdresses?.map((macInfo: any) => macInfo?.lanStatus), limaStatus: val?.macAdresses[0]?.limaStatus, switchPort: val?.macAdresses?.map((macInfo: any) => macInfo?.switchPort) }
  })

  const entranceDetailsColumnsTurkey = [
    {
      title: "ENTRANCE ID",
      dataIndex: "entranceID",
      key: "entranceID",
      width: 100,
      render: (text: any) => <span style={{ marginLeft: "15px" }}>{text}</span>,
    },
    {
      title: "ENTRANCE NAME",
      dataIndex: "entranceName",
      key: "entranceName",
      width: 100,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "LIMA STATUS",
      dataIndex: "limaStatus",
      key: "limaStatus",
      width: 100,
      render: (text: any) => <span style={{ marginLeft: "15px" }}>{text === 1 ? "Active" : "Inactive"}</span>,
    },
    {
      title: "VISIT COUNT",
      dataIndex: "visitorCount",
      key: "visitorCount",
      width: 100,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "MAC ID",
      dataIndex: "macId",
      key: "macId",
      width: 100,
      render: (text: any) => (
        text?.map((macId: any) => { return (<><span>{macId}</span><br></br></>) })
      ),
    },
    {
      title: "LAN STATUS",
      dataIndex: "lanStatus",
      key: "lanStatus",
      width: 100,
      // render: (text: any) => <span>{text}</span>,
      render: (text: any) => (
        text?.map((lanStatus: any) => { return (<><span>{lanStatus}</span><br></br></>) })
      ),
    },
    {
      title: "SWITCH PORT",
      dataIndex: "switchPort",
      key: "switchPort",
      width: 100,
      // render: (text: any) => <span>{text}</span>,
      render: (text: any) => (
        text?.map((switchPort: any) => { return (<><span>{switchPort}</span><br></br></>) })
      ),
    },
  ]

  const fetchEntranceDetails = async (storeId: any) => {
    let token = await GetAccessToken();
    setLoadingEntranceDetails(true); // Set loading to true before fetch
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetCustomerCounterEnteranceDetails?storeId=${storeId}&BDD=${startDate}`,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setLoadingEntranceDetails(false);
        logAPIResponse([], `Failed to fetch customer counter entrance details`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setEntanceDetails(data);
      setLoading(false);
    } catch (error: any) {
      logAPIResponse([], `Failed to fetch customer counter entrance details ${error}`)
      setLoadingEntranceDetails(false);
      console.error(
        "Error while fetching Customer Counter Entrance details:",
        error
      );
    } finally {
      setLoadingEntranceDetails(false); // Set loading to false after fetch
    }
  };

  const fetchCustomerCounterReport = async (params: any) => {
    let token = await GetAccessToken();
    setLoading(true); // Set loading to true before fetch
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/CustomerCounter/GetCustomerCounterReport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setLoading(false);
        logAPIResponse([], `Failed to fetch customer counter report`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setCustomerCounterData(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      logAPIResponse([], `Failed to fetch customer counter report ${error}`)
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  useEffect(() => {
    // if (selectedStoreId?.length > 0 || defaultStores?.length > 0) {
    const params = {
      reportDate: startDate
        ? formatDateToDDMMYYYY(startDate)
        : formatDateToDDMMYYYY(yesterday),
      storeId: selectedStoreId?.length > 0 ? selectedStoreId : defaultStores,
    };
    if (selectedStoreId?.length > 0) {
      fetchCustomerCounterReport(params);
    }
  }, [
    startDate,
    selectedStoreId,
    defaultStores,
    selectedCountryId,
    defaultSelectedCountryId,
    location.search,
  ]);

  const fetchStoresList = async () => {
    let token = await GetAccessToken();
    setLoading(true);
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetStoreFilter`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        setLoading(false);
        logAPIResponse([], `Failed to fetch customer counter store filter`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStoreFilterResponse(data);
      setLoading(false);
    } catch (error: any) {
      logAPIResponse([], `Failed to fetch customer counter store filter ${error}`)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoresList();
  }, []);
  useEffect(() => {
    if (!selectedCountries && storeFilterResponse?.storeCountries) {
      let defaultCountry = GetLoggedInCountry();
      const params = new URLSearchParams(location.search);
      const reportDate = params.get("reportDate");
      const countries: any =
        (params.get("countries") && params.get("countries")?.split(",")) || [];
      const stores: any =
        (params.get("storeId") && params.get("storeId")?.split(",")) || [];
      if (reportDate) {
        setStartDate(formatDateToDDMMYYYY(reportDate));
      } else {
        setStartDate(formatDateToDDMMYYYY(yesterday));
      }
      if (defaultCountry) {
        let allCountriesStores: any = [];
        let allCountries: any = [];
        storeFilterResponse?.storeCountries?.forEach((item: any) => {
          allCountries.push({
            value: item?.countryName,
            key: item?.countryId,
          });
        });
        const filteredCountries = storeFilterResponse?.storeCountries?.filter(
          (country: any) => {
            if (countries?.length > 0) {
              return countries?.includes(country.countryName);
            } else {
              return country.countryName == defaultCountry;
            }
          }
        );

        filteredCountries?.forEach((item: any) => {
          allCountriesStores?.push(
            ...item.stores?.map((item: any) => ({
              value: item?.storeId,
              key: item?.storeId,
            }))
          );

          if (stores?.length === 0 && countries?.length === 1 || defaultCountry) {
            setDefaultStores([item?.stores[0].storeId]);
            setSelectedStores([item?.stores[0].storeId]);
            setSelectedStoreId([item?.stores[0].storeId]);
          }
        });

        if (countries?.length > 0 || defaultCountry) {
          let defaultSelectedCountryList =
            countries?.length > 0 ? countries : [defaultCountry];
          let countriesList: any = [];
          allCountries?.forEach((item: any) => {
            if (defaultSelectedCountryList?.includes(item?.value)) {
              countriesList.push(item.value);
            }
          });
          setSelectedCountryId(countriesList);
          setSelectedCountries(countriesList?.join(","));
          setDefaultSelectedCountryId(countriesList);
        }

        let allSelectedStoresList = allCountriesStores
          ?.filter((item: any) => stores?.includes(item?.value))
          ?.map((item: any) => item?.value);
        if (allSelectedStoresList?.length > 0) {
          setDefaultStores(allSelectedStoresList);
          setSelectedStores(allSelectedStoresList);
          setSelectedStoreId(allSelectedStoresList);
        }

        setSelectedCountryWithCode(filteredCountries);
        setStoresList(allCountriesStores);
        setStoreToMsgList(allCountriesStores);
        setCountryOptions(allCountries);
      }
    }
  }, [storeFilterResponse]);

  const sendServiceNotificationMessage = async (params: any) => {
    let token = await GetAccessToken();
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/CustomerCounter/SaveServiceNotificationMessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        logAPIResponse([], `Failed to save customer counter service notification message`)
        throw new Error("Network response was not ok");
      }
      notification.success({
        message: "Success",
        description: "Message saved successfully",
      });
    } catch (error: any) {
      logAPIResponse([], `Failed to save customer counter service notification message ${error}`)

      notification.error({
        message: "Error",
        description: "Failed to save the message!",
      });
    }
  };

  const sendServiceMessage = (rowData: any) => {
    const params: any = {
      storeId: rowData?.id,
      userId: "",
      serviceMessage: rowData?.serviceNotificationMessage || "",
    };
    if (rowData?.id) {
      sendServiceNotificationMessage(params);
    }
  };

  const handleDateChange = (date: any) => {
    if (date) {
      setStartDate(formatDateToDDMMYYYY(date));
    } else {
      // setStartDate("");
    }
  };

  const handleCheckboxChange = (updatedItems: any[]) => {
    if (updatedItems?.length === 0) {
      handleUnSelectStore();
    } else {
      setSelectedStoreId([...updatedItems]);
      setSelectedStores(updatedItems);
    }
  };
  const handleCountryCheckboxChange = (updatedItems: any[]) => {
    if (updatedItems?.length > 0) {
      setSelectedCountryId([...updatedItems]);
      setSelectedCountries(updatedItems?.join(","));
      let allCountriesStores: any = [];
      const filteredCountries = storeFilterResponse?.storeCountries?.filter(
        (country: any) => updatedItems?.includes(country.countryName)
      );

      setSelectedCountryWithCode(filteredCountries);
      filteredCountries?.forEach((item: any, index: any) => {
        allCountriesStores?.push(
          ...item.stores?.map((item: any) => ({
            value: item?.storeId,
            key: item?.storeId,
          }))
        );
      });
      setStoresList(allCountriesStores);
      setStoreToMsgList(allCountriesStores);
      filterStoresbasedOnCountryChange(filteredCountries);
      setStoreToMsgList(allCountriesStores);
    } else {
      setUncheckingList(selectedStoreId);
      setSelectedStoreId([]);
      setUncheckStoresToMsgList(selectedStoreToMsg);
      setSelectedStoreToMsg([]);
      setSelectedStoreToMsgName("");
      setSelectedStores([]);
      handleClearCountry();
    }
  };

  // Filtering stores based on country change
  const filterStoresbasedOnCountryChange = (filteredCountries: []) => {
    const countryCode = filteredCountries?.map((val: any) => {
      return val.countryId;
    });

    const removedStoreId: any = [];
    const filteredStoreId: any = [];
    selectedStoreId.forEach((storeId: any) => {
      if (countryCode.includes(storeId.slice(0, 2))) {
        filteredStoreId.push(storeId);
      } else {
        removedStoreId.push(storeId);
      }
    });
    setUncheckingList(removedStoreId);

    // To remove respective selected stores to send msg on removing country
    const removedStoreToMsgId: any = [];
    const filteredStoreToMsgId: any = [];
    // if (selectedStoreToMsg.length > 0) {
    selectedStoreToMsg.forEach((storeId: any) => {
      if (countryCode.includes(storeId.slice(0, 2))) {
        filteredStoreToMsgId.push(storeId);
      } else {
        removedStoreToMsgId.push(storeId);
      }
    });
    setUncheckStoresToMsgList(removedStoreToMsgId);
    setSelectedStoreToMsg(filteredStoreToMsgId);
    setSelectedStoreToMsgName(filteredStoreToMsgId.join(", "));
    // }

    storesList.forEach((val: any) => {
      if (removedStoreId.includes(val.key)) {
        // console.log("Present = ",val, removedStoreId)
      }
    });

    setSelectedStoreId(filteredStoreId);
    setSelectedStores(filteredStoreId);
  };

  // clearing stores on clear all countries
  useEffect(() => {
    if (clearCustCounterStore) {
      let defaultCountry = GetLoggedInCountry();
      // setSelectedStores("");
      setSelectedCountryId([defaultCountry]);
      setSelectedCountries(defaultCountry);

      setSelectedStoreId([]);
      setSelectedStores([]);
      setUncheckingList(selectedStoreId);

      // setStoreToMsgList([]);
      setSelectedStoreToMsgName("");
      setUncheckStoresToMsgList(selectedStoreToMsg);
    }
    setClearCustCounterStore(false);
  }, [clearCustCounterStore]);

  const handleLaunchStoreVisitorReportModal = async (record: any) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    setStatusDataStoreId(record.id);
    setIsModalOpen(true);
    const result = await handleFetchVisitorsCount(
      record?.id,
      startDate ? startDate : formatDateToDDMMYYYY(yesterday)
    );
    let report = [];
    const startHour = result?.startTransaction?.split(":")[0];
    const endHour = result?.endTransaction?.split(":")[0];
    for (const val of result?.visitorCountMetrics) {
      const hourSlotHour = val?.timeInternal?.split("-")[0];
      report.push({
        timeInternal: `${val?.timeInternal} ${hourSlotHour === startHour
          ? `(First Transaction: ${result?.startTransaction})`
          : hourSlotHour === endHour
            ? `(Last Transaction: ${result?.endTransaction})`
            : ""
          }`,
        inCount: val?.inCount,
        outCount: val?.outCount,
      });
    }
    setStoreVisitorReport(report);
  };

  const findFirstNLasTxnHour = (value: any) => {
    const hourSlotHour = value.split("-")[0];
    const startHour = startTransaction.split(":")[0];
    const endHour = endTransaction.split(":")[0];
    if (hourSlotHour === startHour)
      return <p>(First Transaction:{startTransaction})</p>;
    else if (hourSlotHour === endHour)
      return `(First Transaction:${startTransaction})`;
  };

  const handleFetchVisitorsCount = async (storeId: any, selectedDate: any) => {
    let token = await GetAccessToken();
    setLoadingVisitorReport(true); // Set loading to true before fetch
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/CustomerCounter/GetStoreVisitorReport?storeId=${storeId}&reportDate=${selectedDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        logAPIResponse([], `Failed to fetch store visitor report`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStartTransaction(data.startTransaction);
      setEndTransaction(data.endTransaction);
      return data;
    } catch (error) {
      logAPIResponse([], `Failed to fetch store visitor report ${error}`)
    } finally {
      setLoadingVisitorReport(false); // Set loading to false after fetch
    }
  };

  const handleResetVisitorReport = () => {
    setStartTransaction("");
    setEndTransaction("");
    setStoreVisitorReport([]);
  };

  const handleStoreToMsgChange = (updatedItems: any[]) => {
    setSelectedStoreToMsg([...updatedItems]);
    setSelectedStoreToMsgName(updatedItems.join(", "));
  };

  const handleSendMsgToStore = async () => {
    const params: any = {
      storeIds: selectedStoreToMsg,
      userId: userEmail,
      serviceMessage: messageTostore,
    };
    if (selectedStoreToMsg?.length && messageTostore?.trim()?.length >= 5) {
      await sendServiceNotificationMessage(params);
      handleCloseSendMsgDialog();
      const allFilterStoresList = storesList?.map((item: any) => item?.value);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const reportParam = {
        reportDate: startDate
          ? formatDateToDDMMYYYY(startDate)
          : formatDateToDDMMYYYY(yesterday),
        storeId:
          selectedStoreId?.length > 0 ? selectedStoreId : allFilterStoresList,
      };
      await fetchCustomerCounterReport(reportParam);
    } else {
      let validationErrMsg = "";
      if (selectedStoreToMsg?.length === 0) {
        validationErrMsg += "Please select the stores.\n";
      }
      if (messageTostore?.trim()?.length < 5) {
        validationErrMsg += "The message should be minimum 5 characters.";
      }
      alert(validationErrMsg);
    }
  };

  const handleCloseSendMsgDialog = () => {
    setShowSendMsgToStoreDialog(false);
    setUncheckStoresToMsgList(selectedStoreToMsg);
    setSelectedStoreToMsg([]);
    setSelectedStoreToMsgName("");
    setMessageTostore("");
  };

  const handleUnSelectStore = () => {
    setDefaultStores([]);
    setSelectedStores([]);
    setSelectedStoreId([]);
    setCustomerCounterData([]);
  };

  const handleClearCountry = async () => {
    setDefaultSelectedCountryId([]);
    setSelectedCountries("");
    setUncheckingList(selectedStores);
    setDefaultStores([]);
    setSelectedStores([]);
    setSelectedStoreId([]);
    setStoresList([]);
    setStoreToMsgList([]);
    setCustomerCounterData([]);
  };


  return (
    <div>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            onClick={() => navigate("/applicationhealth")}
          >
            Application Health
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >
            Customer Counter
          </a>
        </div>
        <div className={styles.customerCountHeader}>
          <h2 className={styles.customerCounterTitle}>Customer Counter</h2>
          <div className={styles.inputContainerDiv}>
            <div className={styles.dateAndCountryDiv}>
              <div className={styles.datePickerContainerDiv}>
                <DatePickerComponent
                  onDateChange={handleDateChange}
                  calendarDate={dayjs(startDate)}
                />
              </div>
              <div
                className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
              >
                <DropDownList
                  items={[
                    {
                      key: `All`,
                      value: `All`,
                      checked: false,
                    },
                    ...countryOptions,
                  ]}
                  onSelect={handleCountryCheckboxChange}
                  unSelectAll={handleClearCountry}
                  selectedDropDownItems={defaultSelectedCountryId}
                  styleName={"customerCounterStyle"}
                  displayName={
                    selectedCountries?.length === 0
                      ? "Choose Country"
                      : selectedCountries
                  }
                  isDisabled={countryOptions?.length === 0}
                  dropdownName="countryDropdown"
                />
              </div>
            </div>
            <div className={styles.storeAndExportBtn}>
              <div
                className={`${styles.dropDownContainerDiv} ${styles.storeDropdown}`}
              >
                <DropDownList
                  items={[
                    {
                      key: `All`,
                      value: `All`,
                      checked: false,
                    },
                    ...storesList,
                  ]}
                  onSelect={handleCheckboxChange}
                  unSelectAll={handleUnSelectStore}
                  selectedDropDownItems={defaultStores}
                  styleName={"customerCounterStoreStyle"}
                  uncheckingList={uncheckingList}
                  displayName={
                    selectedStores?.length === 0
                      ? "Choose store"
                      : selectedStores?.join(",")
                  }
                  isDisabled={storesList?.length === 0}
                  dropdownName="storeDropdown"
                />
              </div>
              <div
                className={styles.exportButtonDiv}
                style={{ marginLeft: "10px" }}
              >
                {/* <ExportToCSV
                  fields={headerList}
                  data={customerCounterData}
                  fileName={`CustomerCounter_${startDate ? startDate : formatDateToDDMMYYYY(yesterday)
                    }`}
                /> */}
                {/* <ExportToExcel
                  jsonData={customerCounterData}
                  headers={headerList}
                  fileName={`CustomerCounter_${startDate ? startDate : formatDateToDDMMYYYY(yesterday)
                    }`}
                /> */}
                <ExportToXslx
                  jsonData={customerCounterData}
                  headers={headerList}
                  fileName={`CustomerCounter_${startDate ? startDate : formatDateToDDMMYYYY(yesterday)
                    }`}
                />
              </div>
              <Divider
                type="vertical"
                className={styles.inputDivider}
              ></Divider>

              <button
                className={styles.sendMsgToStorePopover}
                onClick={() => {
                  setShowSendMsgToStoreDialog(true);
                }}
              >
                Send Message to Store
              </button>
              <div
                hidden={!showSendMsgToStoreDialog}
                className={styles.sendMsgToStoreContainer}
              >
                <div className={styles.sendMsgHeading}>
                  <span>Send Message</span>
                  <span
                    className={styles.closeIcon}
                    onClick={() => {
                      handleCloseSendMsgDialog();
                    }}
                  ></span>
                </div>
                <div>
                  <div className={styles.sendMsgStores}>
                    <DropDownList
                      items={[
                        {
                          key: `All`,
                          value: `All`,
                          checked: false,
                        },
                        ...storeToMsgList,
                      ]}
                      onSelect={handleStoreToMsgChange}
                      styleName={"customerCounterStoreToMsgStyle"}
                      uncheckStoresToMsgList={uncheckStoresToMsgList}
                      displayName={
                        selectedStoreToMsgName?.length === 0
                          ? "Choose store"
                          : selectedStoreToMsgName
                      }
                      isDisabled={storeToMsgList?.length === 0}
                      dropdownName="storeDropdown"
                    />
                  </div>
                  <textarea
                    rows={5}
                    value={messageTostore}
                    className={styles.messageTxtArea}
                    onChange={(e) => setMessageTostore(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className={styles.sendMsgBtn}
                  onClick={() => {
                    handleSendMsgToStore();
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>
        <div className={styles.tableContainer}>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <CustomTable columns={columns} data={customerCounterData} />
          )}
        </div>
      </div>
      <CModal
        alignment="center"
        visible={isModalOpen}
        onClose={() => {
          handleResetVisitorReport();
          setIsModalOpen(false);
        }}
        style={{ borderRadius: "12px" }}
      >
        <CModalHeader
          className="modal-header"
          style={{ borderBottom: "none", paddingTop: "0px" }}
        >
          <CModalTitle id="LiveDemoExampleLabel">
            <div className={styles.modalTitle}>
              Store ID : {statusDataStoreId}
            </div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className={styles.transactionDiv}>
            <div className={styles.transactionInModal}>
              First Transaction:{" "}
              <span className={styles.transactionTime}>{startTransaction}</span>
            </div>
            <div className={styles.transactionInModal}>
              Last Transaction:{" "}
              <span className={styles.transactionTime}>{endTransaction}</span>
            </div>
          </div>
          {loadingVisitorReport ? (
            <div className={styles.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={statusModalColumn}
              dataSource={storeVisitorReport}
              pagination={false}
              style={{
                border: "1px solid #CCCCCC",
                width: "100vw",
                height: "60vh",
                overflowX: "scroll",
                overflowY: "scroll",
                fontSize: "12px",
              }}
              scroll={{ y: "100vh" }}
              tableLayout="fixed"
            />
          )}
        </CModalBody>
      </CModal>

      <CModal
        alignment="center"
        visible={isActiveCounterModalOpen}
        // className={styles.entranceDetailsModal}
        className="entranceInfo-modal"
        onClose={() => {
          // handleResetVisitorReport();
          setEntanceDetails([])
          setIsActiveCounterModalOpen(false);
        }}
        style={{ borderRadius: "12px" }}
      >
        <CModalHeader
          className="modal-header"
          style={{ borderBottom: "none", paddingTop: "0px" }}
        >
          <CModalTitle id="entranceDetails">
            <div className={styles.modalTitle}>
              Entrance Details
            </div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {loadingEntranceDetails ? (
            <div className={styles.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <CustomTable
              columns={selectedEntranceCountry?.slice(0, 2) === "TR" ? entranceDetailsColumnsTurkey : entranceDetailsColumnsNonTurkey}
              data={flattenedEntranceDetails}
            // pagination={false}
            // style={{
            //   border: "1px solid #CCCCCC",
            //   width: "100vw",
            //   height: "60vh",
            //   overflowX: "scroll",
            //   overflowY: "scroll",
            //   fontSize: "12px",
            // }}
            // scroll={{ y: "100vh" }}
            // tableLayout="fixed"
            />
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};
export default CustomerCounter;
