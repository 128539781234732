import React, { useEffect, useRef, useState } from "react";
import styles from "./StoreNotification.module.css";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CImage,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import AppHeaderMobile from "../../components/AppHeaderMobile";
import { GetUserName } from "../../services/AuthUser";
import {
  Button,
  Modal,
  Checkbox,
  Select,
  Breadcrumb,
  Divider,
  Row,
  Col,
  DatePicker,
  Space,
  DatePickerProps,
  Input,
  Collapse,
  notification,
  Spin,
} from "antd";
import type { CheckboxProps, CollapseProps, GetProp } from "antd";
import moment from "moment";

import AppHeader from "../../components/AppHeader";
import stores from "../../data/stores.json";
import countries from "../../data/countries.json";
import TextArea from "antd/es/input/TextArea";
import DropDownList from "../../components/CommonComponents/DropDownList";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Header from "../../components/CommonComponents/Header";
import dayjs from "dayjs";
import { logAPIResponse } from "../../components/CommonUtils/utils/logs_Utils";

type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[number];

const CheckboxGroup = Checkbox.Group;

// const plainOptions = [
//   { label: <span className="check-handm-icon"></span>, value: "H&m" },
//   { label: <span className="check-cos-icon"></span>, value: "COS" },
//   { label: <span className="check-weekday-icon"></span>, value: "WEEKDAY" },
//   { label: <span className="check-monkl-icon"></span>, value: "MONKL" },
//   {
//     label: <span className="check-otherstories-icon"></span>,
//     value: "Other Stories",
//   },
//   { label: <span className="check-arket-icon"></span>, value: "ARKET" },
// ];

const plainOptions = [
  { label: <span className="check-handm-icon"></span>, value: "H&M" },
  { label: <span className="check-cos-icon"></span>, value: "COS" },
  { label: <span className="check-weekday-icon"></span>, value: "Weekday" },
  { label: <span className="check-monkl-icon"></span>, value: "MONKI" },
  {
    label: <span className="check-otherstories-icon"></span>,
    value: "Stories",
  },
  { label: <span className="check-arket-icon"></span>, value: "ARKET" },
];

const defaultCheckedList = [""];
const { Option } = Select;

const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor cursus id commodo nunc ornare suspendisse ullamcorper sed fringilla. Enim, sodales 
`;
interface Notification {
  notificationId: string;
  applicationNames: string[];
  storeId: string;
  brands: string[];
  notificationMessage: string;
  subjectTitle: string;
  startDate?: string;
  endDate?: string;
}
interface ApplicationOption {
  key: string;
  value: string;
  checked: boolean;
}
interface CombinedNotification
  extends Omit<Notification, "notificationId" | "storeId"> {
  notificationId: string;
  storeId: string;
}
const StoreNotification = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const navigate = useNavigate();

  const { confirm } = Modal;
  // const [checkedList, setCheckedList] =
  //   useState<CheckboxValueType[]>(defaultCheckedList);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [notificationDetails, setNotificationDetails] = useState<any | null>(
    null
  );
  const [applicationDetails, setApplicationDetails] = useState<any | null>(
    null
  );
  const [applicationOptions, setApplicationOptions] = useState<
    ApplicationOption[]
  >([]);
  const [storeOptions, setStoresOptions] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState<any | null>([]);
  const [countryOptionsCopy, setCountryOptionsCopy] = useState<any | null>([]);
  const [selectedApplication, setSelectedApplication] = useState<any | null>(
    []
  );
  const [selectedStore, setSelectedStore] = useState<any | null>([]);
  const [selectedCountry, setSelectedCountry] = useState<any | null>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<any | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any | null>(null);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [subjectTitle, setSubjectTitle] = useState("");
  const [startDate, setStartDate] = useState<any | null>(null);
  const [endDate, setEndDate] = useState<any | null>(null);
  const [notificationMessageDialog, setNotificationMessageDialog] =
    useState("");
  const [subjectTitleDialog, setSubjectTitleDialog] = useState("");
  const [startDateDialog, setStartDateDialog] = useState<any | null>(null);
  const [endDateDialog, setEndDateDialog] = useState<any | null>(null);

  const [displayStartDateDialog, setDisplayStartDateDialog] = useState<
    any | null
  >(null);
  const [displayEndDateDialog, setDisplayEndDateDialog] = useState<any | null>(
    null
  );

  const [activeItems, setActiveItems] = useState<any[]>([]);
  const [historyItems, setHistoryItems] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentNotification, setCurrentNotification] =
    useState<Notification | null>(null);
  const [displaySelectedApplication, setDisplaySelectedApplication] =
    useState("");
  const [displaySelectedCountry, setDisplaySelectedCountry] = useState("");
  const [displaySelectedStore, setDisplaySelectedStore] = useState("");
  const [storesList, setStoresList] = useState<any>([]);
  const [brandsCountries, setBrandsCountries] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [loadingSaveNotification, setLoadingSaveNotification] = useState(false);
  const [uncheckingList, setUncheckingList] = useState<any>([]);
  const [disableEndDate, setDisableEndDate] = useState<boolean>(false);
  const [disableEndDateInUpdateModal, setDisableEndDateInUpdateModal] =
    useState<boolean>(false);
  const [endDateMsg, setEndDateMsg] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventTypeDialog, setEventTypeDialog] = useState("");
  const [brandStoreResponse, setBrandStoreResponse] = useState<string[]>([]);
  const [brandAndCountries, setbrandAndCountries] = useState<any>("");
  const [brandAndStores, setbrandAndStores] = useState<any>([]);
  const [brandAndCountryStores, setbrandAndCountryStores] = useState<any>("");
  const [uncheckingCountries, setUncheckingCountries] = useState<any>([]);

  const innerElementRef: any = useRef(null);
  const containerRef: any = useRef(null);

  const onCollapseChange = (key: string | string[]) => {
    // console.log(key);
  };
  const groupNotifications = (
    notifications: Notification[]
  ): CombinedNotification[] => {
    const grouped: { [key: string]: CombinedNotification } = {};

    notifications.forEach((notification) => {
      const key = JSON.stringify({
        startDate: notification.startDate,
        endDate: notification.endDate,
        applicationNames: notification.applicationNames,
        notificationMessage: notification.notificationMessage,
        subjectTitle: notification.subjectTitle,
      });

      if (!grouped[key]) {
        // console.log("Notification id = ",notification.storeId[0])
        grouped[key] = {
          ...notification,
          notificationId: notification.notificationId.toString(),
          storeId: notification.storeId[0]?.trim(),
        };
      } else {
        grouped[key].notificationId += `, ${notification.notificationId}`;
        grouped[key].storeId += `, ${notification.storeId.trim()}`;
      }
    });

    return Object.values(grouped);
  };

  const fetchNotificationDetails = async () => {
    let token = await GetAccessToken();
    setLoading(true);
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreNotification/GetNotificationDetailsList`,
        {
          method: "GET",
          headers: {
            Accept: "text/plain",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (!response.ok) {
        logAPIResponse([], `Failed to fetch store notification details, /StoreNotification/GetNotificationDetailsList `)
        throw new Error("Network response was not ok");
      }

      const data: any = await response.text(); // Assuming the response is text/plain
      const parsedData = JSON.parse(data);
      setNotificationDetails(parsedData);
      // const combinedActiveNotifications = groupNotifications(
      //   parsedData.activeNotifications
      // );
      // console.log("Notifications are = ", combinedActiveNotifications)
      const activeItemsFormatted = parsedData.activeNotifications.map(
        (item: any) => ({
          key: item.notificationId,
          label: `${item.subjectTitle}`,
          children: (
            <div className={styles.notificationitem}>
              <span className={styles.notificationmessage}>
                {item?.notificationMessage ? item?.notificationMessage : ""}
              </span>
              <div className={styles.buttongroup}>
                <button
                  id="update-btn"
                  className={styles.iconbutton}
                  onClick={() => {
                    handleUpdate(item);
                  }}
                >
                  <EditOutlined />
                </button>
                <button
                  id="delete-btn"
                  className={styles.iconbutton}
                  onClick={() => handleDelete(item.notificationId)}
                >
                  <DeleteOutlined />
                </button>
              </div>
            </div>
          ),
        })
      );

      activeItemsFormatted.sort((a: any, b: any) => b.key - a.key);

      // Transform historyNotifications
      // const combinedHistoryNotifications = groupNotifications(
      //   parsedData.historyNotifications
      // );
      const historyItemsFormatted = parsedData.historyNotifications.map(
        (item: any) => ({
          key: item.notificationId,
          label: `${item.subjectTitle}`,
          children: (
            <div className={styles.notificationitem}>
              <span className={styles.notificationmessage}>
                {item?.notificationMessage ? item?.notificationMessage : ""}
              </span>
              <div className={styles.buttongroup}>
                <button
                  id="update-btn"
                  className={styles.iconbutton}
                  onClick={() => {
                    handleUpdate(item);
                  }}
                >
                  <EditOutlined />
                </button>
                <button
                  id="delete-btn"
                  className={styles.iconbutton}
                  onClick={() => handleDelete(item.notificationId)}
                >
                  <DeleteOutlined />
                </button>
              </div>
            </div>
          ),
        })
      );

      historyItemsFormatted.sort((a: any, b: any) => b.key - a.key);

      // Set state with transformed data
      setActiveItems(activeItemsFormatted);
      setHistoryItems(historyItemsFormatted);
    } catch (error) {
      setLoading(false);
      logAPIResponse([], `Failed to fetch store notification details, /StoreNotification/GetNotificationDetailsList ${error}`)
    }
  };
  useEffect(() => {
    fetchNotificationDetails();
  }, []);
  const handleUpdate = (notification: any) => {
    setCurrentNotification(notification);
    setNotificationMessageDialog(notification.notificationMessage);
    setSubjectTitleDialog(notification.subjectTitle);
    // setStartDateDialog(
    //   notification.startDate ? moment(notification.startDate) : null
    // );
    // setDisplayStartDateDialog(
    //   notification.startDate ? notification.startDate : null
    // );
    // setEndDateDialog(
    //   notification.endDate ? moment(notification.endDate) : null
    // );
    // setDisplayEndDateDialog(
    //   notification.endDate ? moment(notification.endDate) : null
    // );
    setStartDateDialog(notification.startDate ? notification.startDate : null);
    setDisplayStartDateDialog(
      notification.startDate ? notification.startDate : null
    );
    setEndDateDialog(notification.endDate ? notification.endDate : null);
    setDisplayEndDateDialog(notification.endDate ? notification.endDate : null);
    if (!notification.endDate) {
      setDisableEndDateInUpdateModal(true);
    }
    setIsModalVisible(true);
    setEventTypeDialog(notification?.eventType);
  };
  const handleDialogSubmitNotification = async () => {
    if (!currentNotification) {
      notification.error({
        message: "Error",
        description: "No notification selected for update.",
      });
      return;
    }
    // console.log("Notification id = ", currentNotification?.notificationId)
    let notificationIdArr = currentNotification?.notificationId;
    // console.log("Store Address = ",currentNotification.storeId)
    let storeIdArr = currentNotification.storeId;
    let successCount = 0;
    let errorCount = 0;

    const checkCompletion = async () => {
      if (successCount + errorCount === notificationIdArr?.length) {
        if (successCount > 0) {
          notification.success({
            message: "Success",
            description: "Notification(s) updated successfully!",
          });
          fetchNotificationDetails();
          setIsModalVisible(false);
        }
        if (errorCount > 0) {
          notification.error({
            message: "Error",
            description:
              "There was an error updating one or more notifications.",
          });
        }
      }
    };

    // for (let i = 0; i < notificationIdArr.length; i++) {
    // console.log("payload date is = ",startDateDialog)
    const payload = {
      notificationId: notificationIdArr,
      applicationNames: currentNotification.applicationNames,
      brands: currentNotification.brands,
      storeId: storeIdArr,
      startDate: startDateDialog ? startDateDialog : null,
      endDate: endDateDialog
        ? disableEndDateInUpdateModal
          ? null
          : endDateDialog
        : null,
      notificationMessage: notificationMessageDialog,
      subjectTitle: subjectTitleDialog,
      eventType: eventTypeDialog,
    };

    // console.log(payload, payload);
    let token = await GetAccessToken();

    let errorMessage = "";

    if (subjectTitleDialog?.trim()?.length < 10) {
      errorMessage += "Subject Title should be atleast 10 characters.\n";
    }

    if (notificationMessageDialog?.trim()?.length < 10) {
      errorMessage += "Notification Message should be atleast 10 characters.\n";
    }

    if (!startDateDialog) {
      errorMessage += "Please select a Start Date.\n";
    }

    if (!endDateDialog && !disableEndDateInUpdateModal) {
      errorMessage += "Please select an End Date.\n";
    }

    if (
      endDateDialog &&
      !disableEndDateInUpdateModal &&
      endDateDialog < startDateDialog
    ) {
      errorMessage += "Start date cannot be greater than end date.\n";
    }

    if (errorMessage) {
      alert(errorMessage.trim());
      return;
    }

    fetch(
      ` ${environmentConfig.chatApiUrl}/StoreNotification/UpdateNotificationDetails`,
      {
        method: "POST",
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        setDisableEndDateInUpdateModal(false);
        if (response.ok) {
          // successCount++;
          notification.success({
            message: "Success",
            description: "Notification(s) updated successfully!",
          });
          fetchNotificationDetails();
          setIsModalVisible(false);
        } else {
          logAPIResponse([], `Failed to update store notification details, /StoreNotification/UpdateNotificationDetails`)
          throw new Error("Failed to update");
        }
      })
      .catch((error) => {
        setDisableEndDateInUpdateModal(false);
        // errorCount++;
        logAPIResponse([], `Failed to update store notification details, /StoreNotification/UpdateNotificationDetails. ${error}`)
        notification.error({
          message: "Error",
          description: "There was an error updating one or more notifications.",
        });
      });
    // .finally(() => {
    //   checkCompletion();
    // });
    // }
  };

  const handleDelete = async (notificationId: any) => {
    let token = await GetAccessToken();
    // console.log("Notification id = ",notificationId)
    confirm({
      title: "Are you sure you want to delete this notification?",
      className: "custom-confirm-modal",
      onOk() {
        let notificationIdArr = notificationId;
        // let successCount = 0;
        // let errorCount = 0;

        // const checkCompletion = () => {
        //   if (successCount + errorCount === notificationIdArr.length) {
        //     if (successCount > 0) {
        //       notification.success({
        //         message: "Success",
        //         description: "Notification(s) deleted successfully!",
        //       });
        //       fetchNotificationDetails();
        //     }
        //     if (errorCount > 0) {
        //       notification.error({
        //         message: "Error",
        //         description:
        //           "There was an error deleting one or more notifications.",
        //       });
        //     }
        //   }
        // };

        // for (let i = 0; i < notificationIdArr.length; i++) {
        fetch(
          `${environmentConfig.chatApiUrl}/StoreNotification/DeleteNotificationDetails?notificationId=${notificationIdArr}`,
          {
            method: "POST",
            headers: {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
            body: "", // Empty body for the POST request
          }
        )
          .then((response) => {
            if (response.ok) {
              // successCount++;
              notification.success({
                message: "Success",
                description: "Notification(s) deleted successfully!",
              });
              fetchNotificationDetails();
            } else {
              logAPIResponse([], `Failed to delete store notification details, /StoreNotification/DeleteNotificationDetails`)
              throw new Error("Failed to delete");
            }
          })
          .catch((error) => {
            logAPIResponse([], `Failed to delete store notification details, /StoreNotification/DeleteNotificationDetails. ${error}`)
            // errorCount++;
            notification.error({
              message: "Error",
              description:
                "There was an error deleting one or more notifications.",
            });
          });
        // .finally(() => {
        //   checkCompletion();
        // });
        // }
      },
      onCancel() {
        // No action needed on cancel
      },
    });
  };

  const fetchStoresList = async () => {
    let token = await GetAccessToken();
    try {
      const response = await fetch(
        ` ${environmentConfig.chatApiUrl}/StoreReport/GetStoreFilter`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        logAPIResponse([], `Failed to fetch store notification store filter, /StoreReport/GetStoreFilter`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStoresList(data);

      // console.log("StoresList is = ", data)
    } catch (error) {
      logAPIResponse([], `Failed to fetch store notification store filter, /StoreReport/GetStoreFilter. ${error}`)
    }
  };

  const findCountryExists = (countryList: any, searchingCountryId: any) => {
    for (const country of countryList) {
      if (country?.key === searchingCountryId) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      let token = await GetAccessToken();
      try {
        const response = await fetch(
          ` ${environmentConfig.chatApiUrl}/StoreNotification/GetNotificationFilter`,
          {
            method: "GET",
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          logAPIResponse([], `Failed to fetch store notification filter, /StoreNotification/GetNotificationFilter`)
          throw new Error("Network response was not ok");
        }

        const data: any = await response.text(); // Assuming the response is text/plain
        const parsedData = JSON.parse(data);
        // console.log("data", data);
        setApplicationDetails(parsedData);
        const options = parsedData?.applications.map((app: any) => ({
          key: app.applicationId,
          value: `${app.applicationName}`,
          checked: false,
        }));
        setApplicationOptions(options);
        // const countryoptions = parsedData?.storeCountries.map((app: any) => ({
        //   key: app.countryId,
        //   value: `${app.countryName}`,
        //   checked: false,
        // }));
        // setCountryOptions(countryoptions);
        // setCountryOptionsCopy(countryoptions)
      } catch (error) {
        logAPIResponse([], `Failed to fetch store notification filter, /StoreNotification/GetNotificationFilter. ${error}`)
      }
    };

    fetchApplicationDetails();
  }, []);

  const filterNotifications = (notifications: any): any => {
    // Convert checkedList to lowercase for case-insensitive comparison
    const lowerCheckedList = checkedList.map((item: any) => item.toLowerCase());

    let tempNotifications = notifications?.filter(
      (notification: any) =>
        notification.brands.some((brand: any) =>
          lowerCheckedList.includes(brand.toLowerCase())
        ) &&
        (!selectedApplication ||
          selectedApplication == notification.applicationID) &&
        (!selectedStore || selectedStore == notification.storeId) &&
        (!selectedCountry || selectedCountry == notification.country) &&
        (!selectedStartDate ||
          new Date(notification.startDate) >= selectedStartDate) &&
        (!selectedEndDate || new Date(notification.endDate) <= selectedEndDate)
    );
    return tempNotifications;
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Notification send to store ID SEO134",
      children: <div>{text}</div>,
    },
    {
      key: "2",
      label: "Notification send to store ID SEO134",
      children: <div>{text}</div>,
    },
    {
      key: "3",
      label: "Notification send to store ID SEO134",
      children: <div>{text}</div>,
    },
  ];
  const checkAll = plainOptions?.length === checkedList?.length;
  const indeterminate =
    checkedList?.length > 0 && checkedList?.length < plainOptions?.length;
  const handleChange = (value: string, type: string) => {
    // console.log(`selected ${value}`);
    if (type === "Application") {
      setSelectedApplication(value);
    } else if (type === "Country") {
      setSelectedCountry(value);
      const selectedStores = applicationDetails.storeCountries
        .filter(
          (country: any) => value === "All" || country.countryId === value
        )
        .flatMap((country: any) => country.stores);
      const storeoptions = selectedStores?.map((app: any) => ({
        key: `${app.storeName.trim()}`,
        value: app.storeId.trim(),
        label: `${app.storeName.trim()}`,
      }));
      setStoresOptions(storeoptions ? storeoptions : []);
    } else if (type === "Store") {
      setSelectedStore(value);
    }
  };

  const handleRenameBrand = (brandName: any) => {
    if (brandName === "WEEKDAY") {
      return "Weekday";
    }
    if (brandName === "H&m") {
      return "H&M";
    }
    if (brandName === "MONKL") {
      return "MONKI";
    }
    if (brandName === "Other Stories") {
      return "Stories";
    }
    if (brandName === "ARKET") {
      return "ARKET";
    }
    if (brandName === "COS") {
      return "COS";
    }
  };

  const handleSetCountryStores = (updatedBrand: any) => {
    // let countryStores:any = []
    // selectedCountryId?.forEach((selectedCountry:any) => {
    //    countryStores.push(brandAndCountryStores?.filter((val: any) => val.country === selectedCountry && val?.brand===updatedBrand))
    // })
    // console.log("selectedCountry", selectedCountry)
    // console.log("updatedBrand", updatedBrand)
    let countryStores: any = selectedCountry?.map((selectedCountry: any) => {
      return brandAndCountryStores?.filter(
        (val: any) =>
          val.country === selectedCountry && val?.brand === updatedBrand
      );
    });
    // const filterOnSelectedBrands = countryStores?.filter((val: any) => selectedBrands?.includes(val?.brand))
    // const totalStores = countryStores?.
    // console.log("countryStores = ", filterOnSelectedBrands)
    // console.log("countryStores = ", countryStores)
    let stores: any = [];
    countryStores.forEach((val: any) => {
      val[0]?.stores?.forEach((val: any) => {
        stores.push({
          key: val,
          value: val,
          checked: false,
        });
      });
    });
    // console.log("stores = ", stores)

    if (selectedCountry?.includes("All")) {
      const selectedCountryWithoutAll = selectedCountry?.filter(
        (val: any) => val !== "All"
      );
      setSelectedCountry(selectedCountryWithoutAll);
      setDisplaySelectedCountry(selectedCountryWithoutAll?.join(", "));
    }

    setStoresOptions((prev: any) => [...prev, ...stores]);
  };

  const handleRemoveCountryStores = (removedBrand: any, updatedList: any) => {
    // unchecking countries of unselected brand only the country is not present for other brands
    const removedBrandCountryObj = brandAndCountries?.find(
      (val: any) => val?.brand == removedBrand
    );
    const removedBrandCountries = removedBrandCountryObj?.countries;
    const selectedCountriesInRemovedBrand = selectedCountry?.filter(
      (val: any) => removedBrandCountries?.includes(val)
    );
    const countryObj = brandAndCountries?.filter((val: any) => {
      return updatedList?.includes(val?.brand);
    });

    const updatedBrandsCountries = countryObj
      ?.map((val: any) => {
        return val?.countries;
      })
      .flat();

    const countryTobeUnchecked = selectedCountry?.filter(
      (val: any) => !updatedBrandsCountries?.includes(val)
    );
    const filteredCountries = selectedCountry?.filter(
      (val: any) => !countryTobeUnchecked?.includes(val)
    );

    setSelectedCountry(filteredCountries);
    setDisplaySelectedCountry(filteredCountries?.join(", "));

    const removedBrandStore = brandAndStores?.filter(
      (val: any) => val?.brand === removedBrand
    );
    const removedStores = removedBrandStore[0]?.stores;

    const filteredSelectedStore = selectedStore?.filter(
      (val: any) => !removedStores?.includes(val)
    );
    // setTimeout(() => {
    //   setUncheckingList(filteredStoresFromStoreDropDown)
    // }, 1500)
    setSelectedStore(filteredSelectedStore);
    setDisplaySelectedStore(filteredSelectedStore?.join(", "));

    const filteredStores = storeOptions.filter(
      (obj1: any) => !removedStores.some((obj2: any) => obj1.key === obj2)
    );
    // console.log("filteredStore = ", filteredStores)

    // setTimeout(() => {
    setStoresOptions(filteredStores);
    // }, 1000)
  };

  const onCheckBoxChange = (list: any[]) => {
    if (list?.length > 0) {
      if (list?.length > checkedList?.length) {
        // console.log("list", list)
        const updatedBrand = list
          ?.filter((val: any) => !checkedList?.includes(val))
          ?.toString();
        handleSetCountryStores(updatedBrand);
        const splittedBrand = list?.join(",");

        setCheckedList(list);
      }
      if (checkedList?.length > list?.length) {
        // console.log("list", checkedList, list)

        const removedBrand = checkedList?.filter(
          (val: any) => !list?.includes(val)
        );
        handleRemoveCountryStores(removedBrand.toString(), list);
        const splittedBrand = list?.join(",");
        setCheckedList(list);
      }
    }
    if (list?.length === 0) {
      setCheckedList([]);
      handleClearCountry();
    }
  };

  const handleClearBrand = async () => {
    handleClearCountry();
  };

  const handleClearCountry = async () => {
    setSelectedStore([]);
    setDisplaySelectedStore("");
    setStoresOptions([]);
    setSelectedCountry([]);
    setDisplaySelectedCountry("");
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      const selectedCountryWithoutAll = selectedCountry?.filter(
        (val: any) => val !== "All"
      );
      setSelectedCountry(selectedCountryWithoutAll);
      setDisplaySelectedCountry(selectedCountryWithoutAll?.join(", "));
    }
    setCountryOptions(countryOptionsCopy);
    setCheckedList(
      e.target.checked ? plainOptions.map((option) => option.value) : []
    );
    if (!e.target.checked) {
      handleClearCountry();
    }
  };
  const handleSubmitNotification = async () => {
    let token = await GetAccessToken();
    let errorMessage = "";

    if (!selectedApplication || selectedApplication?.length === 0) {
      errorMessage += "Please select an Application.\n";
    }

    if (!selectedCountry) {
      errorMessage += "Please select a Country.\n";
    }

    if (selectedStore?.length === 0) {
      errorMessage += "Please select a Store.\n";
    }

    if (subjectTitle?.trim()?.length < 10) {
      errorMessage += "Subject Title should be atleast 10 characters.\n";
    }

    if (notificationMessage?.trim()?.length < 10) {
      errorMessage += "Notification Message should be atleast 10 characters.\n";
    }

    if (!startDate) {
      errorMessage += "Please select a Start Date.\n";
    }

    if (!endDate && !disableEndDate) {
      errorMessage += "Please select an End Date.\n";
    }

    if (endDate && !disableEndDate && endDate < startDate) {
      errorMessage += "Start date cannot be greater than end date.\n";
    }

    if (!eventType) {
      errorMessage += "Please choose Type of disturbance.\n";
    }

    if (errorMessage) {
      alert(errorMessage.trim());
      return;
    }
    let notificationDetails = [];
    // for (const store of selectedStore) {
    notificationDetails.push({
      notificationId: 0,
      applicationNames: selectedApplication?.includes("All")
        ? selectedApplication?.filter((val: any) => val !== "All")
        : selectedApplication,
      storeId: selectedStore?.includes("All")
        ? selectedStore?.filter((val: any) => val !== "All")
        : selectedStore,
      startDate: startDate.toISOString(),
      endDate: endDateMsg?.length > 0 ? null : endDate.toISOString(),
      brands: checkedList?.includes("All")
        ? checkedList?.filter((val: any) => val !== "All")
        : checkedList,
      country: "",
      notificationMessage: notificationMessage,
      subjectTitle: subjectTitle,
      eventType: eventType,
    });
    // }

    try {
      setLoadingSaveNotification(true);
      const response = await fetch(
        ` ${environmentConfig.chatApiUrl}/StoreNotification/SaveNotificationDetails`,
        {
          method: "POST",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(notificationDetails),
        }
      );
      setLoadingSaveNotification(false);
      if (!response.ok) {
        logAPIResponse([], `Failed to save store notification details, /StoreNotification/SaveNotificationDetails`)
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Notification submitted successfully:", data);
      notification.success({
        message: "Success",
        description: "Notification submitted successfully!",
      });
      fetchNotificationDetails();
    } catch (error) {
      logAPIResponse([], `Failed to save store notification details, /StoreNotification/SaveNotificationDetails. ${error}`)
      notification.error({
        message: "Error",
        description: "There was an error submitting the notification.",
      });
    }
  };

  const handleSetStores = (addedCountry: string[]) => {
    if (!addedCountry?.includes("All")) {
      const countryStores = brandAndCountryStores?.filter(
        (val: any) => val.country === addedCountry[0]
      );
      const filterOnSelectedBrands = countryStores?.filter((val: any) =>
        checkedList?.includes(val?.brand)
      );
      // console.log("countryStores = ", filterOnSelectedBrands)
      let stores: any = [];
      filterOnSelectedBrands.forEach((filteredVal: any) => {
        filteredVal?.stores?.forEach((val: any) => {
          stores.push({
            key: val,
            value: val,
            checked: false,
          });
        });
      });
      if (selectedStore?.includes("All")) {
        const selectedStoresWithoutAll = selectedStore?.filter(
          (val: any) => val !== "All"
        );
        setSelectedStore(selectedStoresWithoutAll);
        setDisplaySelectedStore(selectedStoresWithoutAll?.join(", "));
      }
      setStoresOptions((prev: any) => [...prev, ...stores]);
    } else {
      const selectedBrandAndStore = brandAndStores?.filter((val: any) =>
        checkedList?.includes(val.brand)
      );
      let selectedBrandAllStores: any = [];
      selectedBrandAndStore?.forEach((brandAndStore: any) =>
        selectedBrandAllStores?.push(...brandAndStore?.stores?.flat())
      );
      // console.log("selectedBrandAllStores", selectedBrandAllStores)

      if (storeOptions?.length > 0) {
        let existingStoreOptions: any = storeOptions?.map(
          (storeOption: any) => storeOption?.key
        );
        const filteredStores = selectedBrandAllStores
          ?.filter((val: any) => !existingStoreOptions?.includes(val))
          ?.map((filteredStore: any) => {
            return {
              key: filteredStore,
              value: filteredStore,
              checked: false,
            };
          });
        // console.log("existingStoreOptions", existingStoreOptions)
        // console.log("filteredStores", filteredStores)
        setStoresOptions([...storeOptions, ...filteredStores]);
      } else {
        const stores = selectedBrandAllStores?.map((filteredStore: any) => {
          return {
            key: filteredStore,
            value: filteredStore,
            checked: false,
          };
        });
        setStoresOptions(stores);
      }
    }
  };

  const handleRemoveStores = (removedCountry: string) => {
    const countryStores = brandAndCountryStores?.filter(
      (val: any) => val.country === removedCountry
    );
    const filterOnSelectedBrands = countryStores?.filter((val: any) =>
      checkedList?.includes(val?.brand)
    );
    // console.log("countryStores = ", filterOnSelectedBrands)
    let stores: any = [];
    filterOnSelectedBrands.forEach((filteredVal: any) => {
      filteredVal?.stores?.forEach((val: any) => {
        stores.push({
          key: val,
          value: val,
          checked: false,
        });
      });
    });
    const removedStores = stores?.map((val: any) => val.key);
    const filteredSelectedStore = selectedStore?.filter(
      (val: any) => !removedStores?.includes(val)
    );
    setSelectedStore(filteredSelectedStore);
    setDisplaySelectedStore(filteredSelectedStore?.join(", "));
    // setUncheckingList(removedStores)

    const filteredStores = storeOptions.filter(
      (obj1: any) => !stores.some((obj2: any) => obj1.key === obj2.key)
    );

    // setTimeout(() => {
    setStoresOptions(filteredStores);
    // }, 1500)
    // console.log("storeOptions stores", storeOptions)
    // console.log(" stores", stores)
    // console.log("remove stores", removedStores)
    // const removeStoreNames = selectedStores?.filter((val: any) => !removedStores?.includes(val))
    // setSelectedStores(removeStoreNames);
  };

  const handleCountryOnchange = (updatedItems: any[]) => {
    // console.log("Selected country = ", selectedCountryId)
    // console.log("Selected country = ", updatedItems)

    if (updatedItems?.length > 0) {
      if (updatedItems?.length > selectedCountry?.length) {
        // const addedCountry = updatedItems[updatedItems?.length - 1]
        const addedCountry = updatedItems?.filter(
          (val: any) => !selectedCountry?.includes(val)
        );
        // console.log("updated country = ", addedCountry)
        handleSetStores(addedCountry);
        setSelectedCountry([...updatedItems]);
        setDisplaySelectedCountry(updatedItems.join(", "));
      } else {
        const removedCountry = selectedCountry.filter(
          (val: any) => !updatedItems?.includes(val)
        );
        handleRemoveStores(removedCountry.toString());
        if (updatedItems?.includes("All")) {
          const updateditemsWithoutAll = updatedItems?.filter(
            (val: any) => val !== "All"
          );
          setSelectedCountry([...updateditemsWithoutAll]);
          setDisplaySelectedCountry(updateditemsWithoutAll.join(", "));
        } else {
          setSelectedCountry([...updatedItems]);
          setDisplaySelectedCountry(updatedItems.join(", "));
        }
      }
    } else {
      setSelectedStore([]);
      setDisplaySelectedStore("");
      handleClearCountry();
    }
  };

  // Filtering stores based on country change
  const filterStoresbasedOnCountryChange = (filteredCountries: []) => {
    const countryCode = filteredCountries.map((val: any) => {
      return val.countryId;
    });

    const removedStoreId: any = [];
    const filteredStoreId: any = [];
    selectedStore?.forEach((storeId: any) => {
      if (countryCode.includes(storeId.slice(0, 2))) {
        filteredStoreId.push(storeId);
      } else {
        removedStoreId.push(storeId);
      }
    });
    setUncheckingList(removedStoreId);
    setTimeout(() => {
      setUncheckingList([]);
      // console.log(" ")
    }, 1000);
    setSelectedStore(filteredStoreId);
    setDisplaySelectedStore(filteredStoreId.join(", "));
  };

  const handleStoreOnchange = (
    updatedItems: any[],
    updatedKeys?: any[],
    value?: any,
    key?: any
  ) => {
    if (updatedItems?.length === 0) {
      handleUnSelectStore();
    } else {
      if (
        updatedItems?.includes("All") &&
        updatedItems?.length < selectedStore?.length
      ) {
        const selectedStoresWithoutAll = updatedItems?.filter(
          (val: any) => val !== "All"
        );
        setSelectedStore([...selectedStoresWithoutAll]);
        setDisplaySelectedStore(selectedStoresWithoutAll.join(", "));
      } else {
        setSelectedStore([...updatedItems]);
        setDisplaySelectedStore(updatedItems.join(", "));
      }
    }
  };

  const handleApplicationOnchange = (
    updatedItems: any[],
    updatedKeys?: any[],
    value?: any,
    key?: any
  ) => {
    if (updatedItems?.length === 0) {
      handleClearApplication();
    } else {
      if (
        updatedItems?.includes("All") &&
        updatedItems?.length < selectedApplication?.length
      ) {
        const applicationWothoutAll = updatedItems?.filter(
          (val: any) => val !== "All"
        );
        setSelectedApplication(applicationWothoutAll);
        setDisplaySelectedApplication(applicationWothoutAll.join(", "));
      } else {
        setSelectedApplication(updatedItems);
        setDisplaySelectedApplication(updatedItems.join(", "));
      }
    }

    // console.log("SlectedItems", updatedItems);
  };

  const handleClearApplication = () => {
    setSelectedApplication([]);
    setDisplaySelectedApplication("");
  };

  const handleDateFormat = (date: any) => {
    const newDate = new Date(date);
    const timezoneOffsetMs = newDate.getTimezoneOffset() * 60 * 1000;
    const localTimeMs = newDate.getTime() - timezoneOffsetMs;
    const localDate = new Date(localTimeMs);
    const formattedLocalDateTime = localDate
      .toLocaleString("sv")
      .replace(" ", "T");
    return dayjs(formattedLocalDateTime);
  };

  const handleFetchCountryStore = async () => {
    let token = await GetAccessToken();
    setPageLoading(true); // Set loading to true before fetch
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreSmartPhone/GetBrandStoreCountryFilter`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        logAPIResponse([], `Failed to fetch get brand store country filter, /StoreSmartPhone/GetBrandStoreCountryFilter`)
        setPageLoading(false);
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      // console.log("country stores = ", data)
      setBrandStoreResponse(data);
      setPageLoading(false);
    } catch (error: any) {
      setPageLoading(false);
      logAPIResponse([], `Failed to fetch get brand store country filter, /StoreSmartPhone/GetBrandStoreCountryFilter. ${error}`)
    } finally {
      setPageLoading(false); // Set loading to false after fetch
    }
  };
  useEffect(() => {
    handleFetchCountryStore();
  }, []);

  useEffect(() => {
    handleSetCountryOnBrand();
  }, [checkedList.length]);

  const handleSetCountryOnBrand = () => {
    let countries: any = [];
    let stores: any = [];
    const filteredRes: any = brandStoreResponse?.filter((brandResponse: any) =>
      checkedList?.includes(brandResponse?.brand)
    );
    handleBrandCountryAndBrandStores();

    filteredRes?.forEach((brandDetails: any) => {
      brandDetails?.storeCountries?.forEach((val: any) => {
        const isObjectInArray = countries?.some(
          (obj: any) => obj.key === val?.countryName
        );
        if (!isObjectInArray) {
          countries.push({
            key: val?.countryName,
            value: val?.countryName,
            checked: true,
          });
        }
      });
    });
    setCountryOptions(countries);
  };

  const handleBrandCountryAndBrandStores = () => {
    const brandStores: any = [];
    const brandCountries: any = [];
    const brandCountrieStores: any = [];
    brandStoreResponse?.forEach((val: any) => {
      val?.storeCountries?.forEach((storeCountry: any) => {
        brandCountrieStores.push({
          brand: val?.brand,
          country: storeCountry?.countryName,
          stores: storeCountry?.stores?.map((store: any) => store?.storeId),
        });
      });
      setbrandAndCountryStores(brandCountrieStores);

      brandCountries.push({
        brand: val?.brand,
        countries: val?.storeCountries?.map(
          (storeCounty: any) => storeCounty?.countryName
        ),
      });
      // setbrandAndCountries(brandAndCountries)
      setbrandAndCountries(brandCountries);
      brandStores.push({
        brand: val?.brand,
        stores: val?.storeCountries
          ?.map((storeCounty: any) => {
            return storeCounty?.stores?.map((store: any) => store?.storeId);
          })
          .flat(),
      });
      setbrandAndStores(brandStores);
    });
    // console.log("brand stores = ", brandStores)
    // console.log("brand country stores = ", brandCountrieStores)
  };

  const handleUnSelectStore = () => {
    setSelectedStore([]);
    setDisplaySelectedStore("");
  };

  if (pageLoading) {
    return (
      <div className={styles.pageLoader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.StoreNotifications}>
      <Header pageTitle="Business Command Center" />
      <div className={styles.StoreNotificationsContainer}>
        <div style={{ marginBottom: "20px" }}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >
            Store Notification
          </a>
        </div>

        <div className={styles.StoreNotificationHeader}>
          <h2 className={styles.StoreNotificationTitle}>
            Send & Manage Store Notification
          </h2>
        </div>
        <div className={styles.brands}>
          <div className={styles.CheckAllBoxContainer}>
            <Checkbox
              className="custom-checkbox"
              // indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              All Brands
            </Checkbox>
          </div>
          <Divider
            className={styles.divider}
            style={{ width: "1px", backgroundColor: "black" }}
            type="vertical"
          />
          <div
            className={styles.CheckBoxGroupContainer + " custom-checkbox-group"}
          >
            <CheckboxGroup
              options={plainOptions}
              value={checkedList}
              onChange={onCheckBoxChange}
            />
          </div>
        </div>
        <span className={styles.filtersearchmsgtitle}>
          Choose Application, Country, Store
        </span>
        <div className={styles.FilterSearch}>
          <div>
            <DropDownList
              items={[
                // { value: "All", label: "Select All" },
                {
                  key: "All",
                  value: `All`,
                  checked: false,
                },
                ...applicationOptions,
              ]}
              onSelect={handleApplicationOnchange}
              unSelectAll={handleClearApplication}
              // styleName="applicationHealthStyle"
              displayName={
                displaySelectedApplication?.length === 0
                  ? "Select Application"
                  : displaySelectedApplication
              }
              styleName={"StoreNotification"}
              selectedDropDownItems={selectedApplication}
              dropdownName="storeDropdown"
            />
          </div>
          {/**<Select
            placeholder="Select Application"
            onChange={(value) => handleChange(value, "Application")}
            options={[
              { value: "All", label: "Select All" },
              ...applicationOptions
            ]}
          />*/}
          <div
            className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
          >
            <DropDownList
              items={[
                // { value: "All", label: "Select All" },
                {
                  key: "All",
                  value: `All`,
                  checked: false,
                },
                ...countryOptions,
              ]}
              onSelect={handleCountryOnchange}
              unSelectAll={handleClearCountry}
              uncheckingList={uncheckingCountries}
              // styleName="applicationHealthStyle"
              dropdownName="countryDropdown"
              displayName={
                displaySelectedCountry?.length === 0
                  ? "Choose Country"
                  : displaySelectedCountry
              }
              styleName={"StoreNotification"}
              selectedDropDownItems={selectedCountry}
              isDisabled={countryOptions?.length === 0}
            />
          </div>
          {/* <Select
            placeholder="Choose Country"
            onChange={(value) => handleChange(value, "Country")}
            options={[{ value: "All", label: "Select All" }, ...countryOptions]}
          /> */}
          <div
            className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
          >
            <DropDownList
              items={[
                // { value: "All", label: "Select All" },
                {
                  key: "All",
                  value: `All`,
                  checked: false,
                },
                ...storeOptions,
              ]}
              onSelect={handleStoreOnchange}
              // styleName="applicationHealthStyle"
              uncheckingList={uncheckingList}
              unSelectAll={handleUnSelectStore}
              displayName={
                displaySelectedStore?.length === 0
                  ? "Choose Store"
                  : displaySelectedStore
              }
              styleName={"StoreNotification"}
              isDisabled={storeOptions?.length === 0}
              selectedDropDownItems={selectedStore}
              dropdownName="storeDropdown"
            />
          </div>
          {/* <Select
            placeholder="Choose Store"
            onChange={(value) => handleChange(value, "Store")}
            options={[{ value: "All", label: "Select All" }, ...storeOptions]}
          /> */}
        </div>
        <Divider></Divider>
        <div className={styles.notificationTitle}>
          <div className={styles.subjectContainer}>
            <span className={styles.subjecttitle}>Subject Title</span>
            <Input
              className={styles.subjectinput}
              placeholder="Input Text"
              value={subjectTitle}
              onChange={(e) => setSubjectTitle(e.target.value)}
            />
            <div className={styles.subjectContainer}>
              <div className={styles.datePickersContainer}>
                <div className={styles.datePickerItem}>
                  <span className={styles.datePickerLabel}>Start Date</span>
                  <DatePicker
                    showTime
                    className={styles.datePicker}
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                  />
                </div>
                <div className={styles.datePickerItem}>
                  <span className={styles.datePickerLabel}>End Date</span>
                  <DatePicker
                    showTime
                    className={styles.datePicker}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    disabled={disableEndDate}
                  />
                  <div style={{ display: "flex", marginTop: "5px" }}>
                    <input
                      type="checkbox"
                      name="notificationEnd"
                      id="notificationEnd"
                      onChange={() => {
                        setDisableEndDate(!disableEndDate);
                        setEndDateMsg("Until further notification");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      htmlFor="notificationEnd"
                      className={styles.UntilFurtherText}
                      style={{ marginLeft: "4px", cursor: "pointer" }}
                    >
                      Until further notification
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.notificationContainer}>
            <span className={styles.notificationmsgtitle}>
              Notification Message
            </span>
            <TextArea
              className={styles.notificationTextArea}
              rows={4}
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
            <div style={{ marginTop: "15px" }}>
              <span>Type of disturbance</span>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    id="downTime"
                    value="Down Time"
                    name="disturbanceType"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    checked={eventType === "Down Time" || false}
                    onChange={(e) => {
                      setEventType(e.target.value);
                    }}
                  />
                  <label htmlFor="downTime" style={{ cursor: "pointer" }}>
                    Down Time
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    id="performanceImpacted"
                    value="Performance Impacted"
                    name="disturbanceType"
                    checked={eventType === "Performance Impacted" || false}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onChange={(e) => {
                      setEventType(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="performanceImpacted"
                    style={{ cursor: "pointer" }}
                  >
                    Performance Impacted
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.notificationContainer}>
            <span >
              Notification Message
            </span>
            <input
            type="radio"
              // className={styles.notificationTextArea}
              // value={notificationMessage}
              // onChange={(e) => setNotificationMessage(e.target.value)}
            />
          </div> */}
        </div>
        <Divider
          style={{ height: "1px", color: "#979797", marginBottom: "0px " }}
        ></Divider>
        {loadingSaveNotification ? (
          <Button
            className={`${styles.submitNotificationBtn} submitNotificationBtn`}
            style={{ marginTop: "50px", opacity: "0.7" }}
          >
            Saving...
          </Button>
        ) : (
          <Button
            className={`${styles.submitNotificationBtn} submitNotificationBtn`}
            onClick={handleSubmitNotification}
            style={{ marginTop: "50px" }}
          >
            Submit Notification
          </Button>
        )}

        <Divider className={styles.notificationDivider}></Divider>
        <div className={styles.activeandHistory}>
          <div className={styles.activeNotification}>
            <div className={styles.activeTitle}>Active Notification</div>
            <Divider className={styles.notificationDivider}></Divider>
            {loading ? (
              <div className={styles.spinnerContainer}>
                <Spin size="large" />
              </div>
            ) : (
              <div className={styles.notificationCollapseContainer}>
                <Collapse
                  defaultActiveKey={["1"]}
                  onChange={onCollapseChange}
                  expandIconPosition={"end"}
                  items={activeItems}
                />
              </div>
            )}
          </div>
          <div className={styles.history}>
            <div className={styles.activeTitle}>History</div>
            <Divider className={styles.notificationDivider}></Divider>
            {loading ? (
              <div className={styles.spinnerContainer}>
                <Spin size="large" />
              </div>
            ) : (
              <div className={styles.notificationCollapseContainer}>
                <Collapse
                  defaultActiveKey={["1"]}
                  onChange={onCollapseChange}
                  expandIconPosition={"end"}
                  items={historyItems}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {/* Render your notifications here using activeItemsFormatted */}

        <Modal
          title={<div ref={containerRef}>Update Notification</div>}
          open={isModalVisible}
          onCancel={() => {
            setDisableEndDateInUpdateModal(false);
            setIsModalVisible(false);
          }}
          footer={null}
          className="update-modal"
        >
          <div
            className={styles.notificationTitle}
            style={{ marginTop: "29px" }}
          >
            <div className={styles.notificationContainer}>
              <span className={styles.notificationmsgtitle}>
                Notification Message
              </span>
              <TextArea
                className={styles.notificationTextArea}
                rows={2}
                value={notificationMessageDialog}
                onChange={(e) => setNotificationMessageDialog(e.target.value)}
              />
              <div style={{ marginTop: "15px" }}>
                <span>Type of disturbance</span>
                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "20px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="downTimeDialog"
                      value="Down Time"
                      checked={eventTypeDialog === "Down Time" || false}
                      name="disturbanceTypeDialog"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onChange={(e) => {
                        setEventTypeDialog(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="downTimeDialog"
                      style={{ cursor: "pointer" }}
                    >
                      Down Time
                    </label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio"
                      id="performanceImpactedDialog"
                      value="Performance Impacted"
                      checked={
                        eventTypeDialog === "Performance Impacted" || false
                      }
                      name="disturbanceTypeDialog"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onChange={(e) => {
                        setEventTypeDialog(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="performanceImpactedDialog"
                      style={{ cursor: "pointer" }}
                    >
                      Performance Impacted
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.subjectContainer}>
              <span className={styles.subjecttitle}>Subject Title</span>
              <Input
                className={styles.subjectinput}
                style={{ marginBottom: "20px" }}
                placeholder="Input Text"
                value={subjectTitleDialog}
                onChange={(e) => setSubjectTitleDialog(e.target.value)}
              />
              <div
                className={`${styles.subjectContainer} bottomSubjectContainer`}
              >
                <div className={styles.datePickersContainer}>
                  <div className={styles.datePickerItem}>
                    <span className={styles.datePickerLabel}>Start Date</span>
                    <DatePicker
                      showTime
                      className={styles.datePicker}
                      value={
                        displayStartDateDialog
                          ? handleDateFormat(displayStartDateDialog)
                          : startDateDialog
                            ? startDateDialog
                            : null
                      }
                      // value={startDateDialog}
                      onChange={(date) => {
                        setDisplayStartDateDialog(null);
                        setStartDateDialog(date);
                      }}
                      onClick={() => {
                        if (innerElementRef.current) {
                          innerElementRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                      onOk={() => {
                        containerRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      onOpenChange={() => {
                        containerRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className={styles.datePickerItem}> */}
                {/* </div> */}
              </div>
              <div
                className={`${styles.subjectContainer} bottomSubjectContainer`}
              >
                <div className={styles.datePickersContainer}>
                  <div className={styles.datePickerItem}>
                    <span className={styles.datePickerLabel}>End Date</span>
                    <DatePicker
                      showTime
                      className={styles.datePicker}
                      value={
                        displayEndDateDialog
                          ? handleDateFormat(displayEndDateDialog)
                          : endDateDialog
                            ? endDateDialog
                            : null
                      }
                      // value={endDateDialog ? handleDateFormat(endDateDialog) : null}
                      onChange={(date) => {
                        setDisplayEndDateDialog(null);
                        setEndDateDialog(date);
                      }}
                      // disabled={ disableEndDateInUpdateModal || !endDateDialog}
                      disabled={disableEndDateInUpdateModal}
                    />
                    <div style={{ marginTop: "5px", cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        name="notificationEndInModal"
                        id="notificationEndInModal"
                        checked={disableEndDateInUpdateModal}
                        onChange={() => {
                          setDisableEndDateInUpdateModal(
                            !disableEndDateInUpdateModal
                          );
                          if (endDateDialog === null) {
                            setEndDateDialog("");
                          }
                          setDisableEndDateInUpdateModal(
                            !disableEndDateInUpdateModal
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        htmlFor="notificationEndInModal"
                        style={{ marginLeft: "4px", cursor: "pointer" }}
                      >
                        Until further notification
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.datePickerItem}> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <Button
            className={styles.submitNotificationBtn}
            onClick={handleDialogSubmitNotification}
          // style={{marginTop:"0px"}}
          >
            Submit Notification
          </Button>
          <div ref={innerElementRef}></div>
        </Modal>
      </div>
    </div>
  );
};
export default StoreNotification;
