const getInitials = (user: string): string => {
  const fullName = user?.split(" ")
  // console.log("Name is = ",fullName[0].charAt(0),fullName[1].charAt(0))
  // console.log("Name is = ",user)
  return `${fullName[0].charAt(0)}${fullName[1].charAt(0)}`
  // return user.charAt(0);
  // .split(" ")
  // .map((initial) => initial[0])
  // .join("")
  // .replace(/[\])}[{(]/g, "");
};

export { getInitials };
