import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
  Label
} from "recharts";


const CustomizedAxisTick = (x:any, y:any, payload:any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
        {payload.value}
      </text>
    </g>
  );
};


const renderCustomizedLabel = (props:any) => {
    const { x, y, width, height, value,labelColor,labelAdjustVal } = props;
  
    return (
      <g>
        <rect
          x={x + width / 2 - 15}
          y={y - 22 }
          width={40}
          height={20}
          fill={labelColor}
          rx={5}
          ry={5}
        />
        <text x={x + labelAdjustVal + width / 2} y={y - 11} fill="#fff" textAnchor="middle" dominantBaseline="middle">
          {/* {value.split(' ')[1]} */}
          {value}
        </text>
      </g>
    );
  };

export default function ColumnChart({data,width,barSize,yAxisTick,barDataKey,labelKey,legend,height,labelColor,columnColor, labelAdjustVal}:any) {

  return (
    <div style={{ overflowX: 'auto' }}> {/* Make the container scrollable */}

    <ResponsiveContainer width="100%" height={height?height:400}>

    <BarChart
          // width={width}
          // height={height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      barSize={barSize}
      // barCategoryGap="100%"
      // barGap={100}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="parameter" interval={0} angle={-90} textAnchor="start" dy={-10} dx={-20}  padding={{ left: 10, right: 10 }}/>
      {/* {data.map((entry:any, index:any) => (
              <Label
                key={`label-${index}`}
                value={entry.parameter}
                position="insideBottom"
                angle={-45}
                offset={10}
                style={{ textAnchor: 'end' }}
              />
            ))} */}
      {/* </XAxis> */}
      <YAxis ticks={yAxisTick}/>
      <Tooltip />
      {legend && <Legend />}
      <Bar dataKey={barDataKey} fill={columnColor} minPointSize={5} radius={[8, 8, 0, 0]}>
        <LabelList dataKey={labelKey} content={(props:any)=>renderCustomizedLabel({...props,labelColor, labelAdjustVal})} />
        {/* <LabelList dataKey={labelKey}  /> */}
      </Bar>
    </BarChart>
    </ResponsiveContainer>
    </div>

  );
}
