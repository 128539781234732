import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./SalesManagement.module.css";
import { CardSection } from "../../components/CommonComponents/CardSection";
import CustomTable from "../../components/CommonComponents/Table";
import GenericButton from "../../components/CommonComponents/GenericButton";
import {
    environmentData,
    EnvironmentData,
    EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import Header from "../../components/CommonComponents/Header";

const SalesManagement = () => {
    let runEnv: keyof EnvironmentData =
        environmentData.run as keyof EnvironmentData;
    const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
    ] as EnvironmentConfig;
    const navigate = useNavigate();
    const [metricesData, setMetricesData] = useState([]);
      const [applicationListData, setApplicationListData] = useState<any>([]);
    const [selectedApplicationDetails, setSelectedApplicationDetails] =
        useState<any>([]);

    const appList = [
        {
            "applicationID": 1,
            "applicationName": "STORE BUSINESS HOURS",
            "alertColor": "Green",
            "alertCount": "0"
        },
        {
            "applicationID": 1,
            "applicationName": "SERVICE - 1",
            "alertColor": "Green",
            "alertCount": "0"
        },
        {
            "applicationID": 1,
            "applicationName": "SERVICE - 2",
            "alertColor": "Green",
            "alertCount": "0"
        },
        {
            "applicationID": 1,
            "applicationName": "SERVICE - 3",
            "alertColor": "Green",
            "alertCount": "0"
        }
    ]

    const columns = [
        {
            title: "METRICS",
            dataIndex: "metrics",
            applicationId: "",
            width: 170,
        },
        {
            title: "METRICS NAME",
            dataIndex: "metricsName",
            applicationId: "metricsName",
            width: 250,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "CONFIGURABLE THRESHHOLD",
            dataIndex: "configurableThreshold",
            applicationId: "configurableThreshold",
            width: 220,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "CURRENT MEASURE",
            dataIndex: "currentMeasure",
            applicationId: "currentMeasure",
            width: 150,
            render: (text: any) => <a style={{ color: "#1E950B" }}>{text}</a>,
        },
        {
            title: "MEASURE TYPE",
            dataIndex: "measureType",
            applicationId: "measureType",
            width: 150,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "CONFIGURATION LEVEL",
            dataIndex: "configurationLevel",
            applicationId: "configurationLevel",
            width: 170,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "MAPPED TO APPLICATION HEALTH",
            dataIndex: "applicationHealth",
            applicationId: "applicationHealth",
            width: 250,
            render: (text: any) => <a>{text}</a>,
        },
    ];

    //   const fetchApplicationList = async () => {
    //     try {
    //       let token = await GetAccessToken();
    //       const response = await fetch(
    //         `${environmentConfig.chatApiUrl}/StoreReport/GetApplicationList`,
    //         {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application-json",
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       );
    //       if (!response.ok) {
    //         throw new Error("Network response was not ok");
    //       }
    //       const data: any = await response.json();
    //       const newData = data.map((item:any)=>({id:item?.applicationID, title:item?.applicationName,color:item?.alertColor, count:item?.alertCount}))
    //       setApplicationListData(newData);
    //       // console.log("applicationList", data, newData);
    //     } catch (error) {
    //       console.error("Error while fetching Application list:" + error);
    //     }
    //   };


    //   const fetchGetApplicationMetrices = async (applicationID: any) => {
    //     const params = new URLSearchParams({ applicationID });
    //     try {
    //       let token = await GetAccessToken();
    //       const response = await fetch(
    //         `${environmentConfig.chatApiUrl}/StoreReport/GetApplicationMetrices?${params.toString()}`,
    //         {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       );
    //       if (!response.ok) {
    //         throw new Error("Network response was not ok");
    //       }
    //       const data: any = await response.json();
    //       setMetricesData(data);
    //     } catch (error) {
    //       console.error("Error while fetching Application metrices data:", error);
    //     }
    //   };

    //   useEffect(() => {
    //     fetchApplicationList();
    //     // fetchGetApplicationMetrices();
    //   }, []);

    const handleCardClick = (value?: any) => {
        // console.log("value", value);
        setSelectedApplicationDetails([value]);
        // fetchGetApplicationMetrices(value?.id || "");
        // if (value === "customercounter") {
        //   navigate("/customer-counter");
        // } else if (
        //   value === "application1" ||
        //   value === "application2" ||
        //   value === "application3"
        // ) {
        //   navigate("/application");
        // }
    };

    const buttonStyle = {
        backgroundColor: "#222222",
        color: "white",
        fontSize: "16px",
        width: "139px",
        height: "48px",
        borderRadius: 0,
    };

    const handleBtnClick = () => {
        // console.log("handleBtnClick", selectedApplicationDetails);
        if (selectedApplicationDetails[0]?.title === "CUSTOMER COUNTER") {
            navigate("/customer-counter");
        }
        if (selectedApplicationDetails[0]?.title === "STORE SMARTPHONE") {
            navigate("/STORESMARTPHONE");
        }
        if (selectedApplicationDetails[0]?.title === "INSTORE CUST CARE - PROD") {
            navigate("/INSTORECUSTCARE-PROD");
        }
        if (selectedApplicationDetails[0]?.title === "STORE SCANNER SYSTEMS (MSS SERVICES)") {
            navigate("/store-scanner-systems");
        }
        // else {
        //   navigate("/applicationhealth");
        // }
    };
    // console.log("applicationID", applicationListData);

    useEffect(()=>{
      const newData = appList.map((item:any)=>({id:item?.applicationID, title:item?.applicationName,color:item?.alertColor, count:item?.alertCount}))
      setApplicationListData(newData)
    },[])

    return (
        <div>
        <Header pageTitle="Business Command Center" />

        <div className={styles.mainDiv}>
            <div className={styles.routePageTitleWrapper}>
                <a className={styles.breadCrumbsLink} style={{ paddingLeft: "0px" }} onClick={() => navigate("/")}>Home</a>
                <a>&gt;</a>
                <a className={styles.breadCrumbsLink} style={{ textDecoration: "underline", cursor: "auto" }}>Sales Management</a>
            </div>
            <div className={styles.pageTitle}>Sales Management</div>
            <div className={styles.devider} />
            <div
                style={{
                    marginBottom: "15px",
                    width: "100%",
                }}
            >
                <Row gutter={[16, 16]} >
                    {applicationListData?.map((item: any) => {
                        return (
                            <Col xs={24} sm={12} md={8} lg={6} key={item.applicationID}>
                                <div style={{ width: "auto", cursor: "pointer" }}>
                                    <CardSection handleClick={handleCardClick} item={item} />
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
            <div>
                <CustomTable columns={columns} data={metricesData} />
            </div>
             <div className={styles?.alignViewDetailsBtn}>
                <GenericButton
                    label="View in details"
                    type="primary"
                    onClick={handleBtnClick}
                    style={buttonStyle}
                />
            </div>
        </div>
        </div>
    );
};
export default SalesManagement;
