import React, { useEffect, useState } from "react";
import DropDownList from "../../components/CommonComponents/DropDownList";
import DatePickerComponent from "../../components/CommonComponents/DatePicker";
import Header from "../../components/CommonComponents/Header";
import styles from "./Pos.module.css";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import dayjs from "dayjs";
import { Divider, Spin, Checkbox } from "antd";
import CustomTable from "../../components/CommonComponents/Table";
import { GetLoggedInCountry } from "../../services/AuthUser";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import { logAPIResponse } from "../../components/CommonUtils/utils/logs_Utils";

function Pos() {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // states
  const [startDate, setStartDate] = useState();
  const [posData, setPosData] = useState([]);

  const [countryOptions, setCountryOptions] = useState<any | null>([]);
  const [selectedCountries, setSelectedCountries] = useState<any>("");
  const [storeFilterResponse, setStoreFilterResponse] = useState<any>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<string[]>([]);
  const [selectedAllCountry, setSelectedAllCountry] = useState(false);
  const [selectedCountryWithCode, setSelectedCountryWithCode] = useState<any>(
    []
  );
  const [selectedStoreId, setSelectedStoreId] = useState<string[]>([]);
  const [defaultStores, setDefaultStores] = useState<any>([]);
  const [defaultSelectedCountryId, setDefaultSelectedCountryId] = useState<any>(
    []
  );
  const [selectedStores, setSelectedStores] = useState<any>([]);
  const [storesList, setStoresList] = useState([]);
  const [loading, setLoading] = useState(false); // New loading state
  const [uncheckingList, setUncheckingList] = useState<any>([]);
  const [showIpAddressList, setShowIpAddressList] = useState<any>([]);

  const convertcDaysToLocalDays = (utcDays: number) => {
    const utcDate = new Date();

    const timeZoneOffsetMinutes = utcDate.getTimezoneOffset();

    const timeZoneOffsetDays = timeZoneOffsetMinutes / (60 * 24);

    const localDays = utcDays - timeZoneOffsetDays;
    return localDays.toFixed(2);
  };

  const convertDays = (text: any) => {
    const days = text?.split(" ");
    days[2] = convertcDaysToLocalDays(days[2]);
    return days.join(" ");
  };

  const handleShowIpAddress = (record: any) => {
    setShowIpAddressList([
      ...showIpAddressList,
      { ip_Address: record.ip_Address },
    ]);
  };

  const handleHideIpAddress = (record: any) => {
    setShowIpAddressList(
      showIpAddressList?.filter(
        (val: any) => val?.ip_Address !== record?.ip_Address
      )
    );
  };

  const columns: any = [
    {
      title: "POS ID",
      dataIndex: "posName",
      key: "posName",
      width: 100,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "SWITCH PORT NO",
      dataIndex: "switch_Port",
      key: "switch_Port",
      width: 100,
      render: (text: any) => <a style={{paddingLeft:"10px"}}>{text?text:"Not Available"}</a>,
    },
    {
      title: "SALES COUNT",
      dataIndex: "sales_Count",
      key: "sales_Count",
      width: 150,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "IP ADDRESS",
      dataIndex: "ip_Address",
      key: "ip_Address",
      width: 150,
      render: (text: any, record: any) => (
        <a>
          {
            showIpAddressList?.find((val: any) => {
              return val?.ip_Address === text;
            }) ? (
              <a
                onClick={() => {
                  handleHideIpAddress(record);
                }}
              >
                {text && text?.length !== 0 ? text : "Data not Available"}
              </a>
            ) : (
              <span
                className={styles.eyeIcon}
                onClick={() => {
                  handleShowIpAddress(record);
                }}
              ></span>
            )}
        </a>
      ),
    },
    {
      title: "MAC ID",
      dataIndex: "mac_ID",
      key: "mac_ID",
      width: 150,
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 110,
      filters: [
        {
          text: "Online",
          value: "online",
        },
        {
          text: "Offline",
          value: "offline",
        },
      ],

      onFilter: (value: any, record: any) =>
        record?.status?.toLowerCase()?.includes(value),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: any) => (
        <div style={{ padding: 8 , display:"flex", flexDirection:"column"}}>
        {[
          { text: 'Online', value: 'online' },
          { text: 'Offline', value: 'offline' },
        ].map((filter) => (
          <Checkbox
            key={filter.value}
            style={{padding:"5px"}}
            onChange={(e) => {
              const newSelectedKeys = e.target.checked
                ? [...selectedKeys, filter.value]
                : selectedKeys.filter((key:any) => key !== filter.value);
              setSelectedKeys(newSelectedKeys);
              confirm(); // Automatically apply the filter
            }}
            checked={selectedKeys.includes(filter.value)}
          >
            {filter.text}
          </Checkbox>
        ))}
      </div>
      ),
      filterIcon: () => <div className={styles.filterIcon}></div>,
      render: (text: any, record: any) => (
        <div>
          <a
            style={{
              color: `${
                text?.split(" ")[0]?.toLowerCase() === "online"
                  ? "green"
                  : "red"
              }`,
            }}
          >
            {text}
          </a>
        </div>
      ),

      // sorter: (a: any, b: any) => a.statusMessage.localeCompare(b.statusMessage),
      sorter: (a: any, b: any) => {
        const getNumber = (str: any) => {
          const match = str?.match(/\d+(\.\d+)?/);
          return match ? parseFloat(match[0]) : 0;
        };
        return getNumber(a?.status) - getNumber(b?.status);
      },
    },
  ];

  const fetchPosReport = async (params: any) => {
    let token = await GetAccessToken();
    setLoading(true); // Set loading to true before fetch
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetStorePosReport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setLoading(false);
        logAPIResponse([], `Failed to fetch POS report, /StoreReport/GetStorePosReport`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setPosData(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      logAPIResponse([], `Failed to fetch POS report, /StoreReport/GetStorePosReport. ${error}`)
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  const filterStoresbasedOnCountryChange = (filteredCountries: []) => {
    const countryCode = filteredCountries?.map((val: any) => {
      return val.countryId;
    });

    const removedStoreId: any = [];
    const filteredStoreId: any = [];
    selectedStoreId.forEach((storeId: any) => {
      if (countryCode.includes(storeId.slice(0, 2))) {
        filteredStoreId.push(storeId);
      } else {
        removedStoreId.push(storeId);
      }
    });
    setUncheckingList(removedStoreId);

    storesList.forEach((val: any) => {
      if (removedStoreId.includes(val.key)) {
        // console.log("Present = ",val, removedStoreId)
      }
    });

    setSelectedStoreId(filteredStoreId);
    setSelectedStores(filteredStoreId);
  };

  const handleCountryCheckboxChange = (updatedItems: any[]) => {
    if (updatedItems?.length > 0) {
      setSelectedCountryId([...updatedItems]);
      setSelectedCountries(updatedItems?.join(","));
      let allCountriesStores: any = [];
      const filteredCountries = storeFilterResponse?.storeCountries?.filter(
        (country: any) => updatedItems?.includes(country.countryName)
      );

      setSelectedCountryWithCode(filteredCountries);
      filteredCountries?.forEach((item: any, index: any) => {
        allCountriesStores?.push(
          ...item.stores?.map((item: any) => ({
            value: item?.storeId,
            key: item?.storeId,
          }))
        );
      });
      setStoresList(allCountriesStores);
      filterStoresbasedOnCountryChange(filteredCountries);
    } else {
      setUncheckingList(selectedStoreId);
      setSelectedStoreId([]);
      setSelectedStores([]);
      handleClearCountry();
    }
  };

  const handleCheckboxChange = (updatedItems: any[]) => {
    if (updatedItems?.length === 0) {
      handleUnSelectStore();
    } else {
      setSelectedStoreId([...updatedItems]);
      setSelectedStores(updatedItems);
    }
  };

  const handleUnSelectStore = () => {
    setDefaultStores([]);
    setSelectedStores([]);
    setSelectedStoreId([]);
    setPosData([]);
  };

  const handleClearCountry = async () => {
    setDefaultSelectedCountryId([]);
    setSelectedCountries("");
    setUncheckingList(selectedStores);
    setDefaultStores([]);
    setSelectedStores([]);
    setSelectedStoreId([]);
    setStoresList([]);
    setPosData([]);
    setSelectedCountryId([]);

    const params = new URLSearchParams();
    params.set("countries", "");
    params.set("storeId", "");
    navigate({ search: params.toString() }, { replace: true });
  };

  useEffect(() => {
    // console.log("def =",defaultSelectedCountryId, selectedCountryId?.toString())
    const params = new URLSearchParams();
    if (selectedCountries) {
      params.set(
        "countries",
        selectedCountryId?.toString() || defaultSelectedCountryId?.toString()
      );
      params.set(
        "storeId",
        selectedStoreId?.toString() || defaultStores?.toString()
      );
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [
    selectedCountries,
    selectedCountryId,
    defaultSelectedCountryId,
    selectedStoreId,
    storeFilterResponse,
  ]);

  const fetchStoresList = async () => {
    let token = await GetAccessToken();
    setLoading(true);
    try {
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetStoreFilter`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        setLoading(false);
        logAPIResponse([], `Failed to fetch POS store filter, /StoreReport/GetStoreFilter`)
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      setStoreFilterResponse(data);
      setLoading(false);
    } catch (error: any) {
      logAPIResponse([], `Failed to fetch POS store filter, /StoreReport/GetStoreFilter. ${error}`)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoresList();
  }, []);

  useEffect(() => {
    if (!selectedCountries && storeFilterResponse?.storeCountries) {
      let defaultCountry = GetLoggedInCountry();
      const params = new URLSearchParams(location.search);
      const countries: any =
        (params.get("countries") && params.get("countries")?.split(",")) || [];
      const stores: any =
        (params.get("storeId") && params.get("storeId")?.split(",")) || [];
      setSelectedCountries(countries);
      if (defaultCountry) {
        let allCountriesStores: any = [];
        let allCountries: any = [];
        storeFilterResponse?.storeCountries?.forEach((item: any) => {
          allCountries.push({
            value: item?.countryName,
            key: item?.countryId,
          });
        });
        const filteredCountries = storeFilterResponse?.storeCountries?.filter(
          (country: any) => {
            if (countries?.length > 0) {
              return countries?.includes(country.countryName);
            } else {
              return country.countryName == defaultCountry;
            }
          }
        );

        filteredCountries?.forEach((item: any) => {
          allCountriesStores?.push(
            ...item.stores?.map((item: any) => ({
              value: item?.storeId,
              key: item?.storeId,
            }))
          );

          if (
            (stores?.length === 0 && countries?.length === 1) ||
            defaultCountry
          ) {
            setDefaultStores([item?.stores[0].storeId]);
            setSelectedStores([item?.stores[0].storeId]);
            setSelectedStoreId([item?.stores[0].storeId]);
          }
        });

        if (countries?.length > 0 || defaultCountry) {
          let defaultSelectedCountryList =
            countries?.length > 0 ? countries : [defaultCountry];
          let countriesList: any = [];
          allCountries?.forEach((item: any) => {
            if (defaultSelectedCountryList?.includes(item?.value)) {
              countriesList.push(item.value);
            }
          });
          setSelectedCountryId(countriesList);
          setSelectedCountries(countriesList?.join(","));
          setDefaultSelectedCountryId(countriesList);
        }

        let allSelectedStoresList = allCountriesStores
          ?.filter((item: any) => stores?.includes(item?.value))
          ?.map((item: any) => item?.value);
        if (allSelectedStoresList?.length > 0) {
          setDefaultStores(allSelectedStoresList);
          setSelectedStores(allSelectedStoresList);
          setSelectedStoreId(allSelectedStoresList);
        }

        setSelectedCountryWithCode(filteredCountries);
        setStoresList(allCountriesStores);
        setCountryOptions(allCountries);
      }
    }
  }, [storeFilterResponse]);

  useEffect(() => {
    // if (selectedStoreId?.length > 0 || defaultStores?.length > 0) {
    const params = {
      Countries: selectedCountryId,
      storeId: selectedStoreId?.length > 0 ? selectedStoreId : defaultStores,
    };
    if (selectedStoreId?.length > 0) {
      fetchPosReport(params);
    }
  }, [
    selectedStoreId,
    defaultStores,
    selectedCountryId,
    defaultSelectedCountryId,
    location.search,
  ]);

  return (
    <div>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            onClick={() => navigate("/applicationhealth")}
          >
            Application Health
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
            style={{ textDecoration: "underline", cursor: "auto" }}
          >
            POS
          </a>
        </div>
        <div className={styles.posHeader}>
          <h2 className={styles.posTitle}>POS</h2>
          <div className={styles.inputContainerDiv}>
            <div
              className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
            >
              <DropDownList
                items={[
                  {
                    key: `All`,
                    value: `All`,
                    checked: false,
                  },
                  ...countryOptions,
                ]}
                onSelect={handleCountryCheckboxChange}
                unSelectAll={handleClearCountry}
                selectedDropDownItems={defaultSelectedCountryId}
                styleName={"customerCounterStyle"}
                displayName={
                  selectedCountries?.length === 0
                    ? "Choose Country"
                    : selectedCountries
                }
                isDisabled={countryOptions?.length === 0}
                dropdownName="countryDropdown"
              />
            </div>
            <div
              className={`${styles.dropDownContainerDiv} ${styles.storeDropdown}`}
            >
              <DropDownList
                items={[
                  {
                    key: `All`,
                    value: `All`,
                    checked: false,
                  },
                  ...storesList,
                ]}
                onSelect={handleCheckboxChange}
                unSelectAll={handleUnSelectStore}
                selectedDropDownItems={defaultStores}
                styleName={"customerCounterStoreStyle"}
                uncheckingList={uncheckingList}
                displayName={
                  selectedStores?.length === 0
                    ? "Choose store"
                    : selectedStores?.join(",")
                }
                isDisabled={storesList?.length === 0}
                dropdownName="storeDropdown"
              />
            </div>
          </div>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>
        <div className={styles.tableContainer}>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <CustomTable columns={columns} data={posData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Pos;
