import React, { useEffect, useState } from "react";
import styles from "./DropDownList.module.css";
import { Divider } from "antd";
import { useAppContext } from "../ContextAndProvider/Context";
// import { FixedSizeList as List } from 'react-window';
import { List, AutoSizer } from "react-virtualized";
import { Checkbox } from "antd";

interface Item {
  value?: any;
  key?: string;
  checked: boolean;
}

interface CheckboxDropDownListProps {
  items?: Item[];
  selectedDropDownItems?: string[];
  onSelect: (
    selected: string[],
    selectedKey?: string[],
    value?: string,
    key?: string
  ) => void;
  handleClearAll?: (isClear: boolean) => void;
  dropDownWidth?: any;
  unSelectAll?: any;
  styleName?: string;
  isTooltipEnabled?: boolean;
  displayName?: string;
  uncheckingList?: string[];
  uncheckStoresToMsgList?: string[];
  isDisabled?: boolean;
  dropdownName?: string;
}

const DropDownList: React.FC<CheckboxDropDownListProps> = ({
  items = [],
  selectedDropDownItems = [],
  onSelect,
  handleClearAll = () => false,
  dropDownWidth = 160,
  unSelectAll,
  styleName,
  isTooltipEnabled = false,
  displayName,
  uncheckingList,
  uncheckStoresToMsgList,
  isDisabled,
  dropdownName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileteredItems, setFilteredItems] = useState<Item[]>(items);
  const [selectedItems, setSelectedItem] = useState<string[]>(
    selectedDropDownItems
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  // Context provide
  const { clearCustCounterStore, setClearCustCounterStore } = useAppContext();

  // style variables
  const applicationHealthStyle = {
    fontFamily: "HM Sans",
    color: "#6F6F6F",
    fontSize: "14px",
    fontWeight: "400px",
    height: "32px",
  };

  const customerCounterStyle = {
    fontFamily: "HM Sans",
    color: "#6F6F6F",
    fontSize: "14px",
    fontWeight: "400px",
    height: "32px",
    width: "100%",
  };

  const customerCounterStoreStyle = {
    fontFamily: "HM Sans",
    color: "#6F6F6F",
    fontSize: "14px",
    fontWeight: "400px",
    height: "32px",
    width: "100%",
  };

  const StoreNotificationStyle = {
    width: "100%",
    height: "32px",
    color: "#6F6F6F",
  };

  const customerCounterStoreToMsgStyle = {
    width: "100%",
    height: "32px",
    color: "#6F6F6F",
  };

  const storeConfigurationStyle = {
    fontFamily: "HM Sans",
    color: "#6F6F6F",
    fontSize: "14px",
    fontWeight: "400px",
    height: "32px",
    width: `${dropDownWidth}px`,
  };

  const dropDownListWidthStyle = {
    width: `${dropDownWidth}px` || 160,
  };
  // Function to set customised styles based on parent component props
  const setStyles = () => {
    if (styleName === "applicationHealthStyle") return applicationHealthStyle;
    if (styleName === "StoreNotification") return StoreNotificationStyle;
    if (styleName === "customerCounterStyle") return customerCounterStyle;
    if (styleName === "customerCounterStoreStyle")
      return customerCounterStoreStyle;
    if (styleName === "customerCounterStoreToMsgStyle")
      return customerCounterStoreToMsgStyle;
    if (styleName === "storeConfigurationStyle") return storeConfigurationStyle;
  };

  const setDropDownWidthStyles = () => {
    return dropDownListWidthStyle;
  };

  const removeDuplicates: any = (arr: any, key: any) => {
    const seen = new Set();
    return arr.filter((item: any) => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  };
  useEffect(() => {
    setSelectedItem(selectedDropDownItems);
  }, [selectedDropDownItems?.length]);

  useEffect(() => {
    const itemsList = removeDuplicates(items, "value");
    setFilteredItems(
      itemsList?.filter((item: any) =>
        item?.value?.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    );
  }, [searchTerm, items]);

  const toggleItems = (value: any, key: any) => {
    if (value === "Select All" || value === "All") {
      const allValues = fileteredItems
        .map((item) => item.value)
        .filter(Boolean); // Filter out undefined values
      const allKeys = fileteredItems
        ?.map((item: any) => item?.key)
        .filter(Boolean); // Filter out undefined values

      if (selectedItems.length === allValues.length) {
        // Deselect all
        // unselecting both country and store filter in customer counter
        handleClearAll(true);

        if (styleName === "customerCounterStyle") {
          clearCountryFilter();
        } else {
          setSelectedItem([]);
          setSelectedKeys([]);
          const updatedFilteredItems = fileteredItems.map((item) => ({
            ...item,
            checked: false,
          }));
          setFilteredItems(updatedFilteredItems);
          onSelect([], [], value, key);
        }
      } else {
        // Select all
        setSelectedItem(allValues);
        setSelectedKeys(allKeys);
        const updatedFilteredItems = fileteredItems.map((item) => ({
          ...item,
          checked: true,
        }));
        setFilteredItems(updatedFilteredItems);
        onSelect(allValues, allKeys, value, key);
      }
    } else {
      const currentIndex = selectedItems.indexOf(value);
      const newChecked = [...selectedItems];
      const newCheckedKeys = [...selectedKeys].filter(Boolean); // Filter out undefined values
      const updatedFilteredItems = fileteredItems.map((item) => {
        if (item.value === value) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
      if (currentIndex === -1) {
        newChecked.push(value);
        newCheckedKeys.push(key);
      } else {
        newChecked.splice(currentIndex, 1);
        newCheckedKeys.splice(currentIndex, 1);
      }
      setSelectedItem(newChecked);
      setSelectedKeys(newCheckedKeys);
      setFilteredItems(updatedFilteredItems);
      onSelect(newChecked, newCheckedKeys, value, key);
    }
  };

  const clearCountryFilter = () => {
    handleClearAll(true);
    setClearCustCounterStore(true);
    setTimeout(() => {
      clearFilter();
    }, 1200);
    // setClearCustCounterStore(true)
  };

  const clearFilter = () => {
    setSelectedItem([]);
    setSelectedKeys([]);
    const updatedFilteredItems = fileteredItems.map((item) => ({
      ...item,
      checked: false,
    }));
    setFilteredItems(updatedFilteredItems);
    onSelect([], [], "All", "All");
    unSelectAll([]);
  };

  useEffect(() => {
    if (uncheckingList && uncheckingList?.length) {
      const updatedFilteredItems = fileteredItems.map((item) => {
        if (uncheckingList.includes(item.value)) {
          setSelectedItem((prevVal: any) =>
            prevVal.filter((val: any) => val !== item.value)
          );
          return { ...item, checked: false };
        }
        return item;
      });
      setFilteredItems(updatedFilteredItems);
    }
  }, [uncheckingList?.length]);

  useEffect(() => {
    if (uncheckStoresToMsgList && uncheckStoresToMsgList?.length) {
      const updatedFilteredItems = fileteredItems.map((item) => {
        if (uncheckStoresToMsgList.includes(item.value)) {
          setSelectedItem((prevVal: any) =>
            prevVal.filter((val: any) => val !== item.value)
          );
          return { ...item, checked: false };
        }
        return item;
      });
      setFilteredItems(updatedFilteredItems);
    }
  }, [uncheckStoresToMsgList?.length]);

  return (
    <div title={isTooltipEnabled ? displayName : ""}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`${styles.dropDownHeader} ${
          isDisabled ? styles.disabled : ""
        } `}
        style={setStyles()}
      >
        <span className={styles.displaySpan}>
          {displayName ? displayName : "Choose Store"}
        </span>
        <span style={{ display: "flex" }}>
          <Divider
            type="vertical"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              width: "1px",
              backgroundColor: "#979797",
            }}
          ></Divider>
          <span className={styles.downArrow}></span>
        </span>
      </div>
      {isOpen && (
        <div
          className={`${styles.inputWrapper} DropDownList_inputWrapper_${styleName}`}
          onMouseLeave={() => setIsOpen(false)}
          style={setDropDownWidthStyles()}
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`${styles.inputSearch} DropDownList_inputSearch_${styleName}`}
            style={setDropDownWidthStyles()}
          />
          <ul className={`${styles.ulStyle} DropDownList_ulStyle_${styleName}`}>
            {fileteredItems && (
              <List
                width={dropDownWidth}
                height={300} // This determines how many items are displayed at a time
                rowCount={fileteredItems.length}
                rowHeight={({ index }) => {
                  const value = fileteredItems[index].value;
                  if (
                    value === "Bosnia and Herzegovina" ||
                    value === "United Arab Emirates" ||
                    value === "STORE SCANNER SYSTEMS (MSS SERVICES)"
                  )
                    return 50;
                  else if (
                    value === "Macedonia, the former Yugoslav Republic of"
                  )
                    return 80;
                  return 35;
                }} // This is the hei
                style={{ width: "100%" }}
                rowRenderer={(val: any) => {
                  const { index, key, style } = val;
                  const item = fileteredItems[index];
                  return (
                    <li key={key} style={style}>
                      <label
                        className={styles.listLabel}
                        style={{
                          borderBottom:
                            item.value === "All" &&
                            styleName !== "StoreNotification"
                              ? "1px solid #696868"
                              : "",
                        }}
                      >
                        <Checkbox
                          className={
                            styles.inputCheckbox + " custom-checkbox-addApp"
                          }
                          checked={selectedItems?.includes(item.value)}
                          onChange={() => toggleItems(item.value, item.key)}
                        />
                        <div
                          className={styles.label}
                          style={{
                            display:
                              dropdownName === "countryDropdown" ||
                              (dropdownName === "storeDropdown" &&
                                item.value === "All")
                                ? "flex"
                                : "",
                          }}
                        >
                          <span>{item.value}</span>{" "}
                          {dropdownName === "countryDropdown" &&
                            item.value === "All" && (
                              <div style={{ display: "flex" }}>
                                {" "}
                                <Divider
                                  type="vertical"
                                  style={{
                                    backgroundColor: "#696868",
                                    height: "100%",
                                    paddingTop: "8px",
                                    paddingBottom: "5px",
                                    marginLeft: "15px",
                                  }}
                                ></Divider>{" "}
                                <button
                                  className={styles.clearFilterBtn}
                                  onClick={() => {
                                    clearCountryFilter();
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            )}
                          {dropdownName === "storeDropdown" &&
                            item.value === "All" && (
                              <div style={{ display: "flex" }}>
                                {" "}
                                <Divider
                                  type="vertical"
                                  style={{
                                    backgroundColor: "#696868",
                                    height: "100%",
                                    paddingTop: "8px",
                                    paddingBottom: "5px",
                                    marginLeft: "15px",
                                  }}
                                ></Divider>{" "}
                                <button
                                  className={styles.clearFilterBtn}
                                  onClick={() => {
                                    clearFilter();
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            )}
                        </div>
                        {/* {item.value} */}
                      </label>
                    </li>
                  );
                }}
              />
            )}
            {/* <List
              height={600}
              width={170}
              itemCount={fileteredItems.length}
              itemSize={20}> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownList;
