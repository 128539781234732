import React, { useEffect, useState } from 'react'
import Header from '../../components/CommonComponents/Header'
import styles from "./AssetInsights.module.css"
import { useNavigate } from 'react-router-dom'
import DropDownList from '../../components/CommonComponents/DropDownList'
import { Divider, Spin, Table } from 'antd'
import { Chart } from 'react-google-charts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';
import PieChart from '../../components/PieChart/PieChart'
import ColumnChart from '../../components/ColumnChart/ColumnChart'
import VersusLineChart from '../../components/VersusLineChart/VersusLineChart'
import { GetAccessToken } from '../../services/AuthToken'
import { EnvironmentConfig, EnvironmentData, environmentData } from '../../environments/env'
import { GetLoggedInCountry } from '../../services/AuthUser'
import queryString from 'query-string'
import { values } from 'lodash'
import dayjs from 'dayjs'
import { logAPIResponse } from '../../components/CommonUtils/utils/logs_Utils'


function AssetInsights() {
    let runEnv: keyof EnvironmentData =
        environmentData.run as keyof EnvironmentData;
    const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
    ] as EnvironmentConfig;

    const navigate = useNavigate()

    const [countryOptions, setCountryOptions] = useState([])
    const [defaultSelectedCountryId, setDefaultSelectedCountryId] = useState<any>([])
    const [selectedCountries, setSelectedCountries] = useState<any>([])
    const [storeOptions, setStoreOptions] = useState<any>([])
    const [uncheckingList, setUncheckingList] = useState<any>([])
    const [defaultStores, setDefaultStores] = useState<any>([])
    const [selectedStores, setSelectedStores] = useState<any>([])
    const [selectedBrands, setSelectedBrands] = useState<any>([])
    const [selectedBrandsName, setSelectedBrandsName] = useState<any>([])
    const [insightData, setInsightData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [filterLoading, setFilterLoading] = useState(false)
    const [brandsCountries, setBrandsCountries] = useState<any[]>([]);
    const [countryOptionsCopy, setCountryOptionsCopy] = useState<any | null>([]);
    const defaultCheckedList = [""];
    const [checkedList, setCheckedList] =
        useState<string[]>(defaultCheckedList);
    const [storesList, setStoresList] = useState<any>([]);
    const [selectedCountryId, setSelectedCountryId] = useState<string[]>([]);
    const [storeFilterResponse, setStoreFilterResponse] = useState<any>([]);
    const [selectedCountryWithCode, setSelectedCountryWithCode] = useState<any>(
        []
    );
    const [selectedStoreId, setSelectedStoreId] = useState<string[]>([]);
    const [brandStoreResponse, setBrandStoreResponse] = useState<string[]>([]);
    const [brandAndCountries, setbrandAndCountries] = useState<any>("");
    const [brandAndStores, setbrandAndStores] = useState<any>([]);
    const [brandAndCountryStores, setbrandAndCountryStores] = useState<any>("");
    const [smartPhoneVsBBTrendData, setSmartPhoneVsBBTrendData] = useState<any>([]);
    const [hardwareVendorData, setHardwareVendordata] = useState<any>([]);
    const [androidVersionDetails, setAndroidVersionDetails] = useState<any>([]);
    const [smartphoneByCountryData, setSmartphoneByCountryData] = useState<any>([]);
    const [smartPhoneUtilizationByCountry, setSmartPhoneUtilizationByCountry] = useState<any>([]);
    const [deviceModalChartData, setDeviceModalChartData] = useState<any>([]);
    const [storesWithOutSmartPhone, setStoresWithOutSmartPhone] = useState<any>([]);
    const [countryCode, setCountryCode] = useState<any>([]);


    const brandList = [{ key: "H&M", value: "H&M", checked: false }, { key: "COS", value: "COS", checked: false }, { key: "Weekday", value: "Weekday", checked: false },
    { key: "MONKI", value: "MONKI", checked: false }, { key: "Stories", value: "Stories", checked: false }, { key: "ARKET", value: "ARKET", checked: false }
    ]

    const handleSetCountryStores = (updatedBrand: any) => {
        let countryStores: any = selectedCountryId?.map((selectedCountry: any) => {
            return brandAndCountryStores?.filter((val: any) => val.country === selectedCountry && val?.brand === updatedBrand)
        })
        // const filterOnSelectedBrands = countryStores?.filter((val: any) => selectedBrands?.includes(val?.brand))
        // const totalStores = countryStores?.
        // console.log("countryStores = ", filterOnSelectedBrands)
        // console.log("countryStores = ", countryStores)
        let stores: any = []
        countryStores.forEach((val: any) => {
            val[0]?.stores?.forEach((val: any) => {
                stores.push({
                    key: val,
                    value: val,
                    checked: false
                })
            })
        })
        // console.log("stores = ", stores)

        if (selectedCountryId?.includes("All")) {
            const selectedCountryWithoutAll = selectedCountryId?.filter((val: any) => val !== "All")
            setSelectedCountryId(selectedCountryWithoutAll)
            setSelectedCountries(selectedCountryWithoutAll?.join(", "))
        }

        setStoreOptions((prev: any) => [...prev, ...stores])
    }

    const handleRemoveCountryStores = (removedBrand: any, updatedList: any) => {
        const removedBrandCountryObj = brandAndCountries?.find((val: any) => val?.brand == removedBrand)
        const removedBrandCountries = removedBrandCountryObj?.countries
        // const selectedCountriesInRemovedBrand = selectedCountryId?.filter((val: any) => removedBrandCountries?.includes(val))
        const countryObj = brandAndCountries?.filter((val: any) => {
            return updatedList?.includes(val?.brand)
        }
        )

        const updatedBrandsCountries = countryObj?.map((val: any) => {
            return val?.countries
        }).flat()

        const countryTobeUnchecked = selectedCountryId?.filter((val: any) => !updatedBrandsCountries?.includes(val))
        const filteredCountries = selectedCountryId?.filter((val: any) => !countryTobeUnchecked?.includes(val))
        // console.log("removedBrandSelectedCountry = ",removedBrandCountryObj,removedBrandCountries)
        // console.log("removedBrandSelectedCountry = ", countryTobeUnchecked)
        setSelectedCountryId(filteredCountries)
        setSelectedCountries(filteredCountries?.join(', '))
        handleSetRemovedCountryCode(countryTobeUnchecked)

        const removedBrandStore = brandAndStores?.filter((val: any) => val?.brand === removedBrand)
        const removedStores = removedBrandStore[0]?.stores

        const filteredSelectedStore = selectedStores?.filter((val: any) => !removedStores?.includes(val))
        setSelectedStores(filteredSelectedStore)

        const filteredStores = storeOptions.filter((obj1: any) => !removedStores.some((obj2: any) => obj1.key === obj2));
        // console.log("filteredStore = ", filteredStores)
        setStoreOptions(filteredStores)
    }

    const handleBrandChange = (list: any[]) => {
        if (list?.length > 0) {
            if (list?.length > selectedBrands?.length) {
                const updatedBrand = list[list?.length - 1]
                handleSetCountryStores(updatedBrand)
                const splittedBrand = list?.join(',')
                setSelectedBrandsName(splittedBrand)
                // console.log("list = ",splittedBrand)
                setSelectedBrands(list)
            }
            if (selectedBrands?.length > list?.length) {
                const removedBrand = selectedBrands?.filter((val: any) => !list?.includes(val))
                handleRemoveCountryStores(removedBrand?.toString(), list)
                const splittedBrand = list?.join(',')
                setSelectedBrandsName(splittedBrand)
                setSelectedBrands(list)
            }

        }
        if (list?.length === 0) {
            setCountryOptions(countryOptionsCopy)
            setSelectedBrands([])
            setSelectedBrandsName([])
            handleClearCountry()
        }
        // console.log("selected brand = ",list)
        setCheckedList(list);
    };

    const handleSetStores = (addedCountry: string[]) => {
        // console.log("addedCountry", addedCountry)
        if (!addedCountry?.includes("All")) {
            let stores: any = []

            addedCountry?.forEach((country: any) => {


                const countryStores = brandAndCountryStores?.filter((val: any) => val.country === country)
                const filterOnSelectedBrands = countryStores?.filter((val: any) => selectedBrands?.includes(val?.brand))
                // console.log("countryStores = ", filterOnSelectedBrands)
                // let stores: any = []
                filterOnSelectedBrands.forEach((filteredVal: any) => {
                    filteredVal?.stores?.forEach((val: any) => {
                        stores.push({
                            key: val,
                            value: val,
                            checked: false
                        })
                    })
                })
            })
            if (selectedStores?.includes("All")) {
                const selectedStoresWithoutAll = selectedStores?.filter((val: any) => val !== "All")
                setSelectedStores(selectedStoresWithoutAll)
            }
            setStoreOptions((prev: any) => [...prev, ...stores])
        } else {
            // console.log("selectedBrandAllStores ll")
            const selectedBrandAndStore = brandAndStores?.filter((val: any) => selectedBrands?.includes(val.brand))

            let selectedBrandAllStores: any = []
            selectedBrandAndStore?.forEach((brandAndStore: any) => selectedBrandAllStores?.push(...brandAndStore?.stores?.flat()))
            // console.log("selectedBrandAllStores", selectedBrandAllStores)

            if (storeOptions?.length > 0) {
                let existingStoreOptions: any = storeOptions?.map((storeOption: any) => storeOption?.key)
                const filteredStores = selectedBrandAllStores?.filter((val: any) => !existingStoreOptions?.includes(val))?.map((filteredStore: any) => {
                    return {
                        key: filteredStore,
                        value: filteredStore,
                        checked: false
                    }
                })
                // console.log("existingStoreOptions", existingStoreOptions)
                // console.log("filteredStores", filteredStores)
                setStoreOptions([...storeOptions, ...filteredStores])
            } else {
                const stores = selectedBrandAllStores?.map((filteredStore: any) => {
                    return {
                        key: filteredStore,
                        value: filteredStore,
                        checked: false
                    }
                })
                setStoreOptions(stores)
            }
        }
    }

    const handleRemoveStores = (removedCountry: string) => {
        const countryStores = brandAndCountryStores?.filter((val: any) => val.country === removedCountry)
        const filterOnSelectedBrands = countryStores?.filter((val: any) => selectedBrands?.includes(val?.brand))
        // console.log("countryStores = ", filterOnSelectedBrands)
        let stores: any = []
        filterOnSelectedBrands.forEach((filteredVal: any) => {
            filteredVal?.stores?.forEach((val: any) => {
                stores.push({
                    key: val,
                    value: val,
                    checked: false
                })
            })
        })
        const removedStores = stores?.map((val: any) => val.key)
        setUncheckingList(removedStores)

        const filteredStores = storeOptions.filter((obj1: any) => !stores.some((obj2: any) => obj1.key === obj2.key));

        setTimeout(() => {
            setStoreOptions(filteredStores)
        }, 1500)
        // console.log("storeOptions stores", storeOptions)
        // console.log(" stores", stores)
        // console.log("remove stores", removedStores)
        const removeStoreNames = selectedStores?.filter((val: any) => !removedStores?.includes(val))
        setSelectedStores(removeStoreNames);
    }

    const handleSetSelectedCountryCode = (updatedCountries: any[]) => {
        // console.log("updatedCountries", updatedCountries)
        const selectedCountryId: string[] = []
        updatedCountries?.forEach((updatedCountry: string) => {
            const codeOfUpdatedCountryObj = countryCode?.find((val: any) => val?.country === updatedCountry)
            if (!selectedCountryWithCode?.includes(codeOfUpdatedCountryObj?.countryId)) {
                selectedCountryId?.push(codeOfUpdatedCountryObj?.countryId)
            }
            // console.log("codeOfUpdatedCountryObj = ", codeOfUpdatedCountryObj)
        })
        setSelectedCountryWithCode([...selectedCountryWithCode, ...selectedCountryId])
    }

    const handleSetRemovedCountryCode = (removedCountries: any[]) => {
        const removedCountryCodes: string[] = []
        removedCountries?.forEach((removedCountry: string) => {
            const codeOfRemovedCountryObj = countryCode?.find((val: any) => val?.country === removedCountry)
            removedCountryCodes?.push(codeOfRemovedCountryObj?.countryId)
        })
        const flteredCountryCodes = selectedCountryWithCode?.filter((val: any) => !removedCountryCodes?.includes(val))
        setSelectedCountryWithCode(flteredCountryCodes)
    }

    const handleCountryCheckboxChange = (updatedItems: any[]) => {
        // console.log("Selected country = ", selectedCountryId)
        // console.log("Selected country = ", updatedItems)

        if (updatedItems?.length > 0) {
            if (updatedItems?.length > selectedCountryId?.length) {
                // const addedCountry = updatedItems[updatedItems?.length - 1]
                const addedCountry = updatedItems?.filter((val: any) => !selectedCountryId?.includes(val))
                // console.log("updated country = ", addedCountry)
                handleSetSelectedCountryCode(addedCountry)
                handleSetStores(addedCountry)
                setSelectedCountryId([...updatedItems]);
                setSelectedCountries(updatedItems?.join(","));

            } else {
                const removedCountry = selectedCountryId.filter((val: any) => !updatedItems?.includes(val))
                handleRemoveStores(removedCountry.toString())
                handleSetRemovedCountryCode(removedCountry)
                if (updatedItems?.includes("All")) {
                    const updateditemsWithoutAll = updatedItems?.filter((val: any) => val !== "All")
                    setSelectedCountryId([...updateditemsWithoutAll]);
                    setSelectedCountries(updateditemsWithoutAll.join(", "));
                } else {
                    setSelectedCountryId([...updatedItems]);
                    setSelectedCountries(updatedItems?.join(","));
                }
                // console.log("countryOptions", defaultSelectedCountryId)

            }
        } else {
            setUncheckingList(selectedStoreId);
            setSelectedStoreId([]);
            setSelectedStores([]);
            handleClearCountry();
        }
    };

    const handleCheckboxChange = (updatedItems: any[]) => {
        if (updatedItems?.length === 0) {
            handleUnSelectStore();
        } else {
            if (updatedItems?.includes("All") && updatedItems?.length < selectedStores?.length) {
                const selectedStoresWithoutAll = updatedItems?.filter((val: any) => val !== "All")
                setSelectedStoreId([...selectedStoresWithoutAll]);
                setSelectedStores(selectedStoresWithoutAll);
            } else {
                setSelectedStoreId([...updatedItems]);
                setSelectedStores(updatedItems);
            }
        }
    };


    const handleClearBrand = async () => {
        handleClearCountry()


        const params = new URLSearchParams();
        params.set("brands", "");
        params.set("countries", "");
        params.set("storeId", "");
        navigate({ search: params.toString() }, { replace: true });
    }

    const handleClearCountry = async () => {
        setDefaultSelectedCountryId([]);
        setSelectedCountryWithCode([])
        setSelectedCountries("");
        setUncheckingList(selectedStores);
        setDefaultStores([]);
        setSelectedStores([]);
        setSelectedStoreId([]);
        setStoreOptions([]);
        // setPosData([]);
        setSelectedCountryId([]);

        const params = new URLSearchParams();
        // params.set("brands", "");
        params.set("countries", "");
        params.set("storeId", "");
        navigate({ search: params.toString() }, { replace: true });
    }

    const handleUnSelectStore = () => {
        setDefaultStores([]);
        setSelectedStores([]);
        setSelectedStoreId([]);
        // setPosData([]);
    };
    const tileData = [{
        heading: 'Smartphones',
        innerTile: [{ label: "Smartphones", value: "4123" }, { label: "Devices", value: "5463" }]
    },
    {
        heading: 'BB Scanners',
        innerTile: [{ label: "Smartphones", value: "4123" }, { label: "Devices", value: "5463" }]
    }, {
        heading: 'Tablets',
        innerTile: [{ label: "Smartphones", value: "4123" }, { label: "Devices", value: "5463" }]
    }, {
        heading: 'RFID Readers',
        innerTile: [{ label: "Smartphones", value: "4123" }, { label: "Devices", value: "5463" }]
    }
    ]

    const data = [
        ['Task', 'Hours per Day'],
        ['Samsung', 60],
        ['Motorola', 30],
        ['Other', 10],
    ];

    const options = {
        // title: 'Hardware Vendor',
        pieHole: 0.55,
        legend: 'none',
        slices: {
            0: { color: '#06B053' }, // Color for 'Samsung'
            1: { color: '#F4A902' }, // Color for 'Motorola'
            2: { color: '#1872c0' }, // Color for 'Other'
        },
    };


    const pieChartColors = ['#8979FF', '#FF928A', '#3CC3DF', '#FFAE4C', '#537FF1', '#6FD195']
    const pieChartOptions = {
        title: "",
        pieHole: 0.4, // Creates a Donut Chart. Does not do anything when is3D is enabled
        is3D: true, // Enables 3D view
        pieSliceText: 'none',
        pieStartAngle: 50, // Rotates the chart
        legend: 'none',
        colors: pieChartColors,
    };

    const handleFetchData = async (params: any) => {
        let token = await GetAccessToken();
        setLoading(true); // Set loading to true before fetch
        try {
            const response = await fetch(
                `${environmentConfig.chatApiUrl}/StoreSmartPhone/GetSmartPhoneReport`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(params),
                }
            );
            if (!response.ok) {
                setLoading(false);
                logAPIResponse([], `Failed to fetch smartphone asset insights report, /StoreSmartPhone/GetSmartPhoneReport`)
                throw new Error("Network response was not ok");
            }
            const data: any = await response.json();
            setInsightData(data);
            handleDataSegregation(data)
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            logAPIResponse([], `Failed to fetch smartphone asset insights report, /StoreSmartPhone/GetSmartPhoneReport. ${error}`)
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };

    const handleDataSegregation = (data: any) => {
        const updatedData: any = data?.smartPhoneBBTrend?.map((val: any) => {
            return {
                ...val,
                // xAxisName: val?.parameter?.split("-")[1]
                "Smartphones": val?.parameterValue1,
                "Bluebird": val?.parameterValue2
            }
        })
        setSmartPhoneVsBBTrendData(updatedData)

        let hardwarevendor: any = [['Device', 'devicevalue']]

        data?.hardwareVendor?.donutParameters?.forEach((element: any) => {
            hardwarevendor?.push([element?.parameterName, element?.parameterValue])
        });
        setHardwareVendordata(hardwarevendor)

        let androidVersionData: any = [["version", "value"]]
        data?.andriodVersionDetails?.donutParameters?.forEach((element: any) => {
            androidVersionData?.push([element?.parameterName, element?.parameterValue])
        });

        setAndroidVersionDetails(androidVersionData)


        setSmartphoneByCountryData(data?.smartPhoneByCountry)
        setSmartPhoneUtilizationByCountry(data?.smartPhoneUtilizationByCountry)
        setDeviceModalChartData(data?.deviceModelGraph)

        setStoresWithOutSmartPhone(data?.storesWithOutSmartPhone)
    }

    const getHardwareValue = (deviceName: string) => {
        const val: any = insightData?.hardwareVendor?.donutParameters?.find((val: any) => val?.parameterName === deviceName)
        return val?.parameterValue
    }

    useEffect(() => {
        const param = {
            storeIDs: selectedStores?.join(","),
            corporateBrandIds: selectedBrands?.join(","),
            countryCodes: selectedCountryWithCode.length === 0 ? "" : selectedCountryWithCode?.join(",")
        }
        handleFetchData(param)
    }, [selectedStores])


    const handleFetchCountryStore = async () => {
        let token = await GetAccessToken();
        setFilterLoading(true); // Set loading to true before fetch
        try {
            const response = await fetch(
                `${environmentConfig.chatApiUrl}/StoreSmartPhone/GetBrandStoreCountryFilter`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                setFilterLoading(false);
                logAPIResponse([], `Failed to fetch brand store country filter, /StoreSmartPhone/GetBrandStoreCountryFilter`)
                throw new Error("Network response was not ok");
            }
            const data: any = await response.json();
            // console.log("country stores = ", data)
            setBrandStoreResponse(data)
            setFilterLoading(false);
            handleUrlSharing()
        } catch (error: any) {
            setFilterLoading(false);
            logAPIResponse([], `Failed to fetch brand store country filter, /StoreSmartPhone/GetBrandStoreCountryFilter. ${error}`)
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };
    useEffect(() => {
        handleFetchCountryStore()
    }, [])

    useEffect(() => {
        handleSetCountryOnBrand()
    }, [selectedBrands.length])

    const handleSetCountryOnBrand = () => {
        let countries: any = []
        let stores: any = []

        const filteredRes: any = brandStoreResponse?.filter((brandResponse: any) => selectedBrands?.includes(brandResponse?.brand))
        // console.log("filtered brand = ", filteredRes)
        handleBrandCountryAndBrandStores()

        filteredRes?.forEach((brandDetails: any) => {
            (brandDetails?.storeCountries?.forEach((val: any) => {
                const isObjectInArray = countries?.some((obj: any) =>
                    obj.key === val?.countryName
                );
                if (!isObjectInArray) {

                    countries.push({
                        key: val?.countryName,
                        value: val?.countryName,
                        checked: true
                    })
                }
            }))
        })
        setCountryOptions(countries)
    }

    const fetchCountryCodeAndStores = () => {
        if (countryCode?.length === 0) {
            const countryAndCountryCode: any = []

            brandStoreResponse?.forEach((val: any) => {
                val?.storeCountries?.forEach((storeCountry: any) => {

                    if (!countryAndCountryCode?.some((obj: any) => Object?.values(obj)?.includes(storeCountry?.countryId))) {
                        countryAndCountryCode?.push({
                            countryId: storeCountry?.countryId,
                            country: storeCountry?.countryName
                        })
                    }
                })
            })
            setCountryCode(countryAndCountryCode)
            // console.log("country and code = ", countryAndCountryCode)
        }
    }

    const handleBrandCountryAndBrandStores = () => {
        const brandStores: any = []
        const brandCountries: any = []
        const brandCountrieStores: any = []
        const countryAndCountryCode: any = []
        fetchCountryCodeAndStores()

        brandStoreResponse?.forEach((val: any) => {
            val?.storeCountries?.forEach((storeCountry: any) => {
                brandCountrieStores.push({
                    brand: val?.brand,
                    // countryId: storeCountry?.countryId,
                    country: storeCountry?.countryName,
                    stores: storeCountry?.stores?.map((store: any) => store?.storeId)
                })
            })
            setbrandAndCountryStores(brandCountrieStores)
            brandCountries.push({
                brand: val?.brand,
                countries: val?.storeCountries?.map((storeCounty: any) => storeCounty?.countryName)
            })
            // setbrandAndCountries(brandAndCountries)
            setbrandAndCountries(brandCountries)
            brandStores.push({
                brand: val?.brand,
                stores: val?.storeCountries?.map((storeCounty: any) => { return storeCounty?.stores?.map((store: any) => store?.storeId) }).flat()
            })
            setbrandAndStores(brandStores)
        });
        // console.log("brand country1 = ", brandCountries)
        // console.log("brand stores = ", brandStores)
        // console.log("brand country stores = ", brandCountrieStores)
    }

    const handleUrlSharing = () => {
        const params = new URLSearchParams(location.search);
        const hash = location.hash.split('?')[1];
        const params2: any = queryString.parse(hash, { arrayFormat: 'comma' });
        // console.log("params2", params2);


        const brandParam = params.get('brands');
        let countryParam: any = []
        let storesParam: any = []
        const storeParam = params.get('stores');
        // console.log("brand val = ", brandParam)
        if (params2?.brands?.length > 0) {
            setSelectedBrands(Array.isArray(params2.brands) ? params2.brands : [params2.brands]);
            handleBrandChange(Array.isArray(params2.brands) ? params2.brands : [params2.brands])
            if (params2?.brands) {
                countryParam = Array.isArray(params2.countries) ? params2.countries : [params2.countries]
            }
            if (params2?.stores) {
                storesParam = Array.isArray(params2.stores) ? params2.stores : [params2.stores]
            }
        } else {
            setSelectedBrands(['H&M']);
            handleBrandChange(['H&M'])
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const brandParam = params.get('brands');
        const hash = location.hash.split('?')[1];
        const params2 = queryString.parse(hash, { arrayFormat: 'comma' });
        // console.log("params2..", params2);
        // console.log("vbrandParam = ", params2?.brands)
        const brands = params2?.brands
        const countries: any = params2?.countries
        const storeId = params2?.storeId

        let defaultCountry = GetLoggedInCountry();
        if (brandAndCountryStores?.length > 0 && !brands) {
            // console.log("Indian store countryStores", brandAndCountryStores)
            setSelectedCountryId([defaultCountry])
            handleCountryCheckboxChange([defaultCountry])
            handleSetSelectedCountryCode([defaultCountry])
            // setTimeout(()=>{
            const countryStores = brandAndCountryStores?.filter((val: any) => val.country === defaultCountry && val.brand === "H&M")
            // console.log("Indian store countryStores", countryStores)

            let stores: any = countryStores[0]?.stores?.map((val: any) => {
                return {
                    key: val,
                    value: val,
                    checked: false
                }
            })

            setStoreOptions(stores)
            // console.log("Indian store countryStores", countryStores)
            setSelectedStores([stores[0]?.key])
            handleCheckboxChange([stores[0]?.key])
            // },1000)
            // console.log("called use")

            const params = new URLSearchParams();
            params.set("brands", 'H&M');
            params.set("countries", defaultCountry);
            params.set("storeId", stores[0]?.key);
            navigate({ search: params.toString() }, { replace: true });
        }

        if (brandAndCountryStores?.length > 0 && brands) {
            setSelectedCountryId(typeof countries === "string" ? [countries] : countries)
            handleCountryCheckboxChange(typeof countries === "string" ? [countries] : countries)
            setSelectedStores(typeof storeId === "string" ? [storeId] : storeId)
        }

    }, [brandAndCountryStores?.length])

    useEffect(() => {
        const params = new URLSearchParams();
        if (selectedCountryId?.length > 0) {
            params.set(
                "brands",
                selectedBrands?.toString() || 'H&M'
            );
            params.set(
                "countries",
                selectedCountryId?.toString() || defaultSelectedCountryId?.toString()
            );
            params.set(
                "storeId",
                selectedStores?.toString() || defaultStores?.toString()
            );
            navigate({ search: params.toString() }, { replace: true });
        }
    }, [
        selectedCountryId,
        selectedStores,
        selectedBrands,
    ]);

    const getBarChartTicks = (data: any, noOfParts: number) => {
        const sortedData = data?.sort((a: any, b: any) => b.count - a.count)
        // console.log("sortedData",sortedData)
        const biggestData = sortedData[0]?.count
        const eachIntervalVal = Math.ceil((biggestData / noOfParts))
        // console.log("eachIntervalVal",eachIntervalVal)
        let ticks = new Array()
        if (biggestData > 0) {
            for (let index = 0; index <= noOfParts + 1; index++) {
                ticks.push(eachIntervalVal * index)
            }
        } else {
            for (let index = 0; index <= noOfParts + 1; index++) {
                ticks.push(10 * index)
            }
        }
        return ticks

    }

    const DeviceCustomYAxis = ({ interval = 0, ticks = getBarChartTicks(deviceModalChartData, 4) }) => {
        return <YAxis interval={interval} ticks={ticks} />;
    };

    const getLastSynCFormat = (val: any) => {
        const splitted = val.split(" ")
        const logDate = splitted[0]?.replaceAll(":", "-")
        let date = dayjs(logDate, 'DD-MM-YYYY');
        // Format the date as "09 Jan 2025"
        let formattedDate = date.format('DD MMM YYYY');
        const formattedTime = splitted[1].slice(0, -3)
        return formattedDate + ", " + formattedTime

    }

    if (filterLoading) {
        return (
            <div className={styles.pageLoader}>
                <Spin size="large" />
            </div>
        )
    }

    return (
        <>
            <Header pageTitle="Business Command Center" />
            <div className={styles.mainDiv}>
                <div className={styles.routePageTitleWrapper}>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ paddingLeft: "0px" }}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </a>
                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                        onClick={() => navigate("/applicationhealth")}
                    >
                        Application Health
                    </a>
                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                        onClick={() => navigate("/STORESMARTPHONE")}
                    >
                        Store Smartphone
                    </a>
                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                    >
                        Asset Insights
                    </a>
                </div>
                <div className={styles.insightsHeader}>
                    <h2 className={styles.assetInsightsTitle}>Store Smartphone-Asset Insights</h2>
                    <div className={styles.inputContainerDiv}>
                        <div
                            className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
                        >
                            <DropDownList
                                items={[
                                    {
                                        key: `All`,
                                        value: `All`,
                                        checked: false,
                                    },
                                    ...brandList,
                                ]}
                                onSelect={handleBrandChange}
                                unSelectAll={handleClearBrand}
                                selectedDropDownItems={selectedBrands}
                                styleName={"customerCounterStyle"}
                                displayName={
                                    selectedBrandsName?.length === 0
                                        ? "Choose Brand"
                                        : selectedBrandsName
                                }
                                // isDisabled={countryOptions?.length === 0}
                                dropdownName="countryDropdown"
                            />
                        </div>
                        <div
                            className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
                        >
                            <DropDownList
                                items={[
                                    {
                                        key: `All`,
                                        value: `All`,
                                        checked: false,
                                    },
                                    ...countryOptions,
                                ]}
                                onSelect={handleCountryCheckboxChange}
                                unSelectAll={handleClearCountry}
                                selectedDropDownItems={selectedCountryId}
                                styleName={"customerCounterStyle"}
                                displayName={
                                    selectedCountries?.length === 0
                                        ? "Choose Country"
                                        : selectedCountries
                                }
                                isDisabled={countryOptions?.length === 0}
                                dropdownName="countryDropdown"
                            />
                        </div>
                        <div
                            className={`${styles.dropDownContainerDiv} ${styles.storeDropdown}`}
                        >
                            <DropDownList
                                items={[
                                    {
                                        key: `All`,
                                        value: `All`,
                                        checked: false,
                                    },
                                    ...storeOptions,
                                ]}
                                onSelect={handleCheckboxChange}
                                unSelectAll={handleUnSelectStore}
                                selectedDropDownItems={selectedStores}
                                styleName={"customerCounterStoreStyle"}
                                uncheckingList={uncheckingList}
                                displayName={
                                    selectedStores?.length === 0
                                        ? "Choose store"
                                        : selectedStores?.join(",")
                                }
                                isDisabled={storeOptions?.length === 0}
                                dropdownName="storeDropdown"
                            />
                        </div>
                    </div>
                </div>
                <Divider
                    style={{
                        marginTop: "12px",
                        marginBottom: "30px",
                        height: "1px",
                        backgroundColor: "#cccccc",
                    }}
                ></Divider>
                {loading ? <div className={styles.pageLoader}>
                    <Spin size="large" />
                </div> :
                    (<div>
                        <div className={styles.cards}>
                            <div className={styles.cardContainer}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className={styles?.cardHeadingAndIconDiv}>
                                        <div className={styles.smartphonesIcon}></div>
                                        <div className={styles.cardMainHeding}>Smartphones</div>
                                    </div>
                                </div>
                                {/* <div className={styles.lastSyncData}>Last sync : {insightData?.smartPhone?.logDate ? getLastSynCFormat(insightData?.bbScanner?.logDate) : "No data. Please contact StoreiService support team."}</div> */}
                                <div className={styles.lastSyncSmartphonesData}>Last sync :</div>
                                <div className={styles.innerCard}>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.smartPhone?.stores}</div>
                                        <div className={styles.cardLabel}>Stores</div>
                                    </div>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.smartPhone?.devices}</div>
                                        <div className={styles.cardLabel}>Devices</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cardContainer}>
                                <div className={styles?.cardHeadingAndIconDiv}>
                                    <div className={styles.BBScannerIcon}></div>
                                    <div className={styles.cardMainHeding}>BB Scanner</div>
                                </div>
                                <div className={styles.lastSyncData}>Last sync : {insightData?.bbScanner?.logDate ? getLastSynCFormat(insightData?.bbScanner?.logDate) : "No data. Please contact StoreiService support team."}</div>
                                <div className={styles.innerCard}>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.bbScanner?.stores}</div>
                                        <div className={styles.cardLabel}>Stores</div>
                                    </div>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.bbScanner?.devices}</div>
                                        <div className={styles.cardLabel}>Devices</div>
                                    </div>
                                </div>
                            </div><div className={styles.cardContainer}>
                                <div className={styles?.cardHeadingAndIconDiv}>
                                    <div className={styles.tabletsIcon}></div>
                                    <div className={styles.cardMainHeding}>Tablets</div>
                                </div>
                                <div className={styles.lastSyncData}>Last sync : {insightData?.tablets?.logDate ? getLastSynCFormat(insightData?.tablets?.logDate) : "No data. Please contact StoreiService support team."}</div>
                                <div className={styles.innerCard}>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.tablets?.stores}</div>
                                        <div className={styles.cardLabel}>Stores</div>
                                    </div>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.tablets?.devices}</div>
                                        <div className={styles.cardLabel}>Devices</div>
                                    </div>
                                </div>
                            </div><div className={styles.cardContainer}>
                                <div className={styles?.cardHeadingAndIconDiv}>
                                    <div className={styles.RfidIcon}></div>
                                    <div className={styles.cardMainHeding}>RFID Readers</div>
                                </div>
                                <div className={styles.lastSyncData}>Last sync : {insightData?.rfidReaders?.logDate ? getLastSynCFormat(insightData?.rfidReaders?.logDate) : "No data. Please contact StoreiService support team."}</div>
                                <div className={styles.innerCard}>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.rfidReaders?.stores}</div>
                                        <div className={styles.cardLabel}>Stores</div>
                                    </div>
                                    <div className={styles.innerCardContainer}>
                                        <div className={styles.cardValue}>{insightData?.rfidReaders?.devices}</div>
                                        <div className={styles.cardLabel}>Devices</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles?.secondRow}>
                            <div className={styles.graphContainer}>
                                <div className={styles.donutChartContainer} >
                                    <div className={styles.donutChartTitle}>Hardware Vendor</div>
                                    <Chart
                                        chartType="PieChart"
                                        width={"98%"}
                                        height="300px"
                                        data={hardwareVendorData}
                                        options={options}
                                    />
                                    <div className={styles.donutChartTotalCount}>{insightData?.hardwareVendor?.total > 0 ? insightData?.hardwareVendor?.total : ""}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 10px", marginBottom: "10px", gap: "20px" }}>
                                    <div style={{ display: "flex", gap: "5px", border: "1px solid #cccccc", padding: "2px 8px", borderRadius: "8px", width: "90%" }}><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#06B053", marginTop: "5px" }}></div><div><div style={{ color: "#555555" }}>Samsung</div><div style={{ color: "#555555" }}>{getHardwareValue("samsung") || 0}</div></div></div>
                                    <div style={{ display: "flex", gap: "5px", border: "1px solid #cccccc", padding: "2px 8px", borderRadius: "8px", width: "90%" }}><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#F4A902", marginTop: "5px" }}></div><div><div style={{ color: "#555555" }}>Motorola</div><div style={{ color: "#555555" }}>{getHardwareValue("motorola") || 0}</div></div></div>
                                    <div style={{ display: "flex", gap: "5px", border: "1px solid #cccccc", padding: "2px 8px", borderRadius: "8px", width: "90%" }}><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#1872c0", marginTop: "5px" }}></div><div><div style={{ color: "#555555" }}>Other</div><div style={{ color: "#555555" }}>{getHardwareValue("Others") || 0}</div></div></div>
                                </div>
                            </div>
                            <div className={styles.columnGraphContainer}>
                                {/* <div style={{ border: "1px solid #AFC0CF", borderRadius: "12px", marginBottom: "20px" }} > */}

                                <div className={styles.donutChartTitle}>Device Modal</div>
                                {deviceModalChartData?.length === 0 ? <div className={styles?.noDataDiv}>No data</div> : (<ResponsiveContainer width="100%" height={350}>
                                    <BarChart
                                        data={deviceModalChartData}
                                        margin={{
                                            top: 60, right: 20, left: 0, bottom: 0,
                                        }}
                                        barSize={15}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="parameter" interval={0} angle={-90} textAnchor="start" dy={-10} dx={-20} padding={{ left: 10, right: 10 }} />
                                        <YAxis interval={0} ticks={getBarChartTicks(deviceModalChartData, 4)} />
                                        <Tooltip />
                                        <Bar dataKey="count" fill="#1872c0" minPointSize={10} />
                                    </BarChart>
                                </ResponsiveContainer>)}
                            </div>
                            <div className={styles.graphContainer}>
                                <div className={`${styles.donutChartContainer} ${styles.donutChartContainerVersionDetails}`} >
                                    <div className={styles.donutChartTitle}>Android Version Details</div>

                                    <Chart
                                        chartType="PieChart"
                                        data={androidVersionDetails}
                                        options={pieChartOptions}
                                        width={"100%"}
                                        height={"290px"}
                                    />
                                    <div style={{ position: "absolute", bottom: "24px", color: "black", fontSize: "14px" }}>Total : {insightData?.andriodVersionDetails?.total}</div>
                                    <div style={{ position: "absolute", bottom: "-20px" }}>
                                        <div style={{ display: "flex", gap: "8px" }}>
                                            <div className={styles.pieChartlegendMain}><div className={styles.pieChartLegendDot} style={{ backgroundColor: '#8979FF' }}></div><span>V-14.0.0</span></div>
                                            <div className={styles.pieChartlegendMain}><div className={styles.pieChartLegendDot} style={{ backgroundColor: "#FF928A" }}></div><span>V-13.0.0</span></div>
                                            <div className={styles.pieChartlegendMain}><div className={styles.pieChartLegendDot} style={{ backgroundColor: "#3CC3DF" }}></div><span>V-12.0.0</span></div>
                                        </div>
                                        <div style={{ display: "flex", gap: "8px" }}>
                                            <div className={styles.pieChartlegendMain}><div className={styles.pieChartLegendDot} style={{ backgroundColor: "#FFAE4C" }}></div><span>V-11.0.0</span></div>
                                            <div className={styles.pieChartlegendMain}><div className={styles.pieChartLegendDot} style={{ backgroundColor: "#537FF1" }}></div><span>V-10.0.0</span></div>
                                            <div className={styles.pieChartlegendMain}><div className={styles.pieChartLegendDot} style={{ backgroundColor: "#6FD195" }}></div><span>V-09.0.0</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div><div className={styles.graphContainer}>
                                <div className={styles.storesWithOutSmartPhoneMain}>
                                    <div style={{ color: "black" }}>Store without Smartphone</div>
                                    <div className={styles.lastSyncData}>Last sync : {insightData?.storesWithOutSmartPhoneLogDate ? getLastSynCFormat(insightData?.storesWithOutSmartPhoneLogDate) : "No data. Please contact StoreiService support team."}</div>
                                    <div className={styles?.tableMainDiv}>
                                        <div className={styles?.tableHeaderDiv}>
                                            <div className={styles?.tableHeaderLeft} >Store ID</div>
                                            <div className={styles?.tableHeaderRight}>BB Scanners</div>
                                        </div>
                                        {storesWithOutSmartPhone?.length === 0 ? <div className={styles?.noTableDataDiv}>No data</div> : (<div className={styles?.tableBodyDiv}>
                                            {storesWithOutSmartPhone?.map((val: any, index: number) => {
                                                return (
                                                    <div key={index} className={`${styles?.tabledatRowDiv} ${index % 2 !== 0 ? styles?.oddTableDatRow : ""}`}>
                                                        <div className={styles?.tabledataleft} >{val?.storeId}</div>
                                                        <div className={styles?.tabledataRight}>{val?.noofDevices}</div>

                                                    </div>
                                                )
                                            })}</div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.columnChartMain}>

                            <div className={styles.smartPhoneByCountryChartDiv} >
                                <div className={styles.smartPhoneByCountryChartHeading}>Smartphone by Country</div>
                                {smartphoneByCountryData?.length === 0 ? <div className={styles?.noTableDataDiv}>No data</div> : <ColumnChart data={smartphoneByCountryData} barDataKey="count" labelKey="count" barSize={15} legend={false} labelColor={"#1872C0"} columnColor={"#F4A902"} labelAdjustVal={4} yAxisTick={getBarChartTicks(smartphoneByCountryData, 4)} />}
                            </div>
                            <div className={styles.smartPhoneUtilByCountryChartDiv}>
                                <div className={styles.smartPhoneUtilByCountryChartHeading}>Smartphone Utilization by Country</div>
                                {smartphoneByCountryData?.length === 0 ? <div className={styles?.noTableDataDiv}>No data</div> : <ColumnChart data={smartPhoneUtilizationByCountry} barDataKey="count" labelKey="count" barSize={15} legend={false} labelColor={"#F4A902"} columnColor={"#1872C0"} labelAdjustVal={4} yAxisTick={getBarChartTicks(smartPhoneUtilizationByCountry, 4)} />}
                            </div>
                        </div>
                        <div className={styles.smartPhoneVsBBTrendMain}>
                            <div className={styles.smartPhoneVsBBTrendChartMain} >
                                <div>Smartphone Vs Bluebird Trend</div>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#00b452" }}></div><div><div style={{ color: "#555555", fontSize: "14px" }}>Smartphones</div></div></div>
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}><div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#F4A902" }}></div><div><div style={{ color: "#555555", fontSize: "14px" }}>Bluebird</div></div></div>
                                </div>

                            </div>
                            <div className={`${styles.lastSyncData} ${styles.smartphoneVsBBSyncData}`}>Last sync : {insightData?.smartPhoneBBTrendLogDate ? getLastSynCFormat(insightData?.smartPhoneBBTrendLogDate) : "No data. Please contact StoreiService support team."}</div>
                            {smartPhoneVsBBTrendData?.length === 0 ? <div className={styles?.noTableDataDiv}>No data</div> : <VersusLineChart chartName="smartphoneVsBBTrend" data={smartPhoneVsBBTrendData} xAxisParam="parameter" firstDataKey='Smartphones' secondDataKey='Bluebird' legend={false} label1Color={'#00b452'} line1Color={'#00b452'} label2Color='#F4A902' line2Color='#F4A902' label1Width={40} label2Width={40} label1AdjustVal={4} label2AdjustVal={4} rightVal={50} topVal={20} />}
                        </div>
                    </div>)}
            </div>
        </>
    )
}

export default AssetInsights