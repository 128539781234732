import { useMsal } from "@azure/msal-react";
import styles from "./AccessDenied.module.css";
import { useNavigate } from "react-router-dom";
import { GetRoles } from "../services/AuthUser";

function AccessDenied() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      localStorage.setItem("redirectingUrl", "/");
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Access denied</div>
      <div className={styles.description}>
        You do not have permission to access this page. To gain access, please
        contact us at
        <a className={styles.link} href="mailto:storeiservice@hm.com">
          storeiservice@hm.com
        </a>
        . Or try logout and login back
      </div>
      <div style={{ margin: "10px 0px", padding: "10px 5px" }}>
        {GetRoles()?.length > 0 && (
          <button
            style={{ padding: "2px 12px", marginRight: "10px" }}
            onClick={() => navigate("/")}
          >
            Back to Home
          </button>
        )}
        <button
          style={{ padding: "2px 12px" }}
          onClick={() => handleLogout("redirect")}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default AccessDenied;
