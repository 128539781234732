import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import styles from "./ExportToXslx.module.css"

interface ExportToXslx {
    jsonData: any[];
    fileName: string;
    headers: { header: string, key: string }[];
}

const ExportToXslx : React.FC<ExportToXslx> = ({ jsonData,headers, fileName }) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define columns
    worksheet.columns = headers

    // Add rows
    jsonData.forEach((item:any) => {
      worksheet.addRow(item);
    });

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <button className={styles.exportButton} onClick={exportToExcel}>Export</button>
  );
};

export default ExportToXslx;
