import { useIsAuthenticated } from "@azure/msal-react";
import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { GetRoles } from "./AuthUser";
import AccessDenied from "../pages/AccessDenied";
import ErrorBoundaryComponent from "../components/ErrorBoundaryComponent/ErrorBoundaryComponent";

interface PrivateRouteProps {
  children: ReactNode;
  role: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, role }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const redirectingUrl = location.pathname?.concat(location?.search);
  if (!isAuthenticated) {
    localStorage.setItem("redirectingUrl", redirectingUrl);
    return <Navigate to="/" state={{ from: redirectingUrl }} replace />;
  } else {
    for (const val of role) {
      if (GetRoles()?.includes(val)) {
        return <><ErrorBoundaryComponent>{children}</ErrorBoundaryComponent></>;
      }
    }
    return <AccessDenied />;
  }
};

export default PrivateRoute;
